import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { clearExtraServices, getExtraServices } from "../../actions/extra_services";
import { getPatientId } from "../../actions/get_patient_id";
import { ARCHES } from "../../common/constants";
import { getLastCorrection, isPatient } from "../../common/patient";
import { useDocumentTitle } from "../../hooks/use-document-title";
import type { TService } from "../../reducers/dashboard";
import { useAppDispatch, useAppSelector } from "../../store";
import { FormatDate } from "../common/FormatDate";
import Loader from "../common/loadingInProgress";
import { RemoteLogErrorBoundary } from "../common/remote-log-error-boundary";
import { Layout } from "../ui/layout";
import { Portlet, PortletTitle } from "../ui/portlet";

const ELASTICS_ID_TO_TRANSLATION = {
  2: "ER_R_II_CLASS",
  3: "ER_R_III_CLASS",
} as const;

export default function AdditionalAlignersTablePage() {
  return (
    <Layout>
      <RemoteLogErrorBoundary component="additional_aligners_table">
        <AdditionalAlignersTable />
      </RemoteLogErrorBoundary>
    </Layout>
  );
}

function AdditionalAlignersTable() {
  const { patient_id } = useParams<{ patient_id: string }>();

  useDocumentTitle(
    useIntl().formatMessage({ id: "pat.additional.aligners.page.header" }) + " " + patient_id,
  );

  const dispatch = useAppDispatch();

  const patient = useAppSelector((state) => state.patient);
  const services = useAppSelector((state) => state.services);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearExtraServices());

    const patientIdAsNumber = Number(patient_id);
    void dispatch(getPatientId(patientIdAsNumber));
    void dispatch(getExtraServices(patientIdAsNumber));
  }, [patient_id, dispatch]);

  const patientIsLoading = !isPatient(patient);

  if (patientIsLoading) {
    return <Loader />;
  }

  const canOrderAdditionalAligners =
    getLastCorrection(patient).order_options.can_order_additional_aligners;

  if (!canOrderAdditionalAligners) {
    return null;
  }

  const servicesAreLoading = !Array.isArray(services);

  return (
    <Portlet as="main" id="additional-aligners-table">
      <PortletTitle iconClassName="icon-users" id="patients-table-block-title">
        <FormattedMessage id="pat.additional.aligners.page.header" />
      </PortletTitle>

      <div className="portlet-body">
        <div id="additional-aligners_table_wrapper" className="dataTables_wrapper no-footer">
          <div className="table-responsive">
            {servicesAreLoading ? (
              <Loader />
            ) : (
              <table
                className="table table-bordered table-hover dataTable no-footer"
                id="additional_aligners_table"
                role="grid"
              >
                <thead id="additional_aligners-head">
                  <tr role="row">
                    <th>
                      <FormattedMessage id="th.stage" />
                    </th>
                    <th>
                      <FormattedMessage id="th.option" />
                    </th>
                    <th>
                      <FormattedMessage id="AMOUNT" />
                    </th>
                    <th>
                      <FormattedMessage id="HEADER_ELASTICS" />
                    </th>
                    <th>
                      <FormattedMessage id="th.status" />
                    </th>
                    <th>
                      <FormattedMessage id="pat_table.received" />
                    </th>
                    <th>
                      <FormattedMessage id="th.timestamp" />
                    </th>
                  </tr>
                </thead>

                <tbody id="additional-aligners-body">
                  {services
                    .filter((service) => service.tag == "ADDITIONAL_ALIGNERS")
                    .map((service, index) => {
                      return (
                        <tr key={`service${index}`}>
                          <td>{service.option.stage}</td>
                          <td>
                            <FormattedMessage id={ARCHES[service.option.treat_arch_id]} />
                          </td>
                          <td>{service.option.amount}</td>
                          <td>{renderElastics(service)}</td>
                          <td>
                            <FormattedMessage id={service.option.status} />
                          </td>
                          <td>
                            <FormattedMessage id={service.paid ? "bool.true" : "bool.false"} />
                          </td>
                          <td>
                            <FormatDate value={service.timestamp} date />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Portlet>
  );
}

function renderElastics(service: TService) {
  const { elastics_left, elastics_right } = service.option;
  if (elastics_left !== null && elastics_right !== null) {
    return (
      <>
        <FormattedMessage id="ELASTICS_RIGHT" />
        &nbsp;
        <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_right]} />
        <br />
        <FormattedMessage id="ELASTICS_LEFT" />
        &nbsp;
        <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_left]} />
      </>
    );
  } else if (elastics_left !== null) {
    return (
      <>
        <FormattedMessage id="ELASTICS_LEFT" />
        &nbsp;
        <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_left]} />
      </>
    );
  } else if (elastics_right !== null) {
    return (
      <>
        <FormattedMessage id="ELASTICS_RIGHT" />
        &nbsp;
        <FormattedMessage id={ELASTICS_ID_TO_TRANSLATION[elastics_right]} />
      </>
    );
  }
}
