import React from "react";

import { deployedRussia } from "../../common/utils";
import type { TUser } from "../../reducers/login";
import { useAppSelector } from "../../store";

type PersonNameProps = Omit<FormatPersonNameProps, "user"> & {
  as?: "span" | "strong"
};

export function PersonName({ as: Tag = "span", ...props }: PersonNameProps) {
  const user = useAppSelector((state) => state.user);

  return (
    <Tag data-matomo-mask data-hj-suppress>
      {formatPersonName({ ...props, user })}
    </Tag>
  );
}

type FormatPersonNameProps = {
  person?: {
    account_id?: number;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
  };
  user?: Partial<TUser>;
  useMiddleName?: boolean;
  isDoctor?: boolean;
};

export function formatPersonName({
  person,
  useMiddleName,
  isDoctor,
  user,
}: FormatPersonNameProps): string {
  if (!person) return "";
  let { first_name, middle_name, last_name } = person;
  if (!middle_name || !useMiddleName || middle_name.toLowerCase() == "none") {
    middle_name = "";
  }

  if (!isDoctor && user) {
    isDoctor = person.account_id == user.account_id;
  }

  let name = [];
  if (deployedRussia()) {
    name = [last_name, first_name, middle_name].filter(Boolean);
  } else {
    name = [first_name, middle_name, last_name].filter(Boolean);
    if (isDoctor) name.unshift("Dr.");
  }

  return name.join(" ");
}

