import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import clsx from "clsx";
import React, { forwardRef } from "react";

const buttonVariants = cva(
  clsx(
    "tw-inline-flex tw-items-center tw-justify-center tw-no-underline tw-transition-colors",
    "tw-whitespace-nowrap",
    "focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600",
    // FIXME: don't apply hover styles when button is disabled
    "disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed disabled:tw-opacity-50",
  ),
  {
    variants: {
      variant: {
        default: clsx(
          "tw-border-0 tw-bg-primary tw-text-white hover:tw-bg-[#26a1ab] active:tw-bg-[#26a1ab]",
          "focus-visible:tw-ring-offset-1",
        ),
        outline: clsx(
          "tw-border-2 tw-border-solid tw-border-[#b0bfc7] tw-bg-white tw-text-[#b0bfc7]",
          "hover:tw-border-black hover:tw-text-black",
          "active:tw-border-black active:tw-text-black",
        ),
        secondary: clsx(
          "tw-border-0 tw-bg-[#efefef] tw-text-[#34495e]",
          "hover:tw-bg-[#E0E0E0]"
        ),
      },
      size: {
        default: "tw-px-4 tw-py-2",
      },
      rounded: {
        true: "tw-rounded-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & { asChild?: boolean };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { className, variant, size, rounded, asChild = false, ...props },
  forwardedRef,
) {
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      ref={forwardedRef}
      className={buttonVariants({ variant, size, rounded, className })}
      {...props}
    />
  );
});
