import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { TPatient } from "./reducers/dashboard";

export type TYooKassaPaymentStatus = "pending" | "succeeded" | "canceled";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.API, credentials: "include" }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["Event", "MeetingFeedback"],
  endpoints: (builder) => ({
    getYear: builder.query<{ year: number }, void>({
      query: () => ({ url: "year" }),
    }),
    getInvoicePaymentStatus: builder.query<
      { status: TYooKassaPaymentStatus },
      { patientId: TPatient["patient_id"]; invoiceId: number; patientToken?: string }
    >({
      query: (params) => {
        const args: { token?: string; patient_id?: number } = {};

        if (typeof params.patientToken == "string") {
          args.token = params.patientToken;
          args.patient_id = params.patientId;
        }

        return {
          url: `doctor/patients/${params.patientId}/invoice/${params.invoiceId}`,
          params: args,
        };
      },
    }),
    getInvoicePaymentBatchStatus: builder.query<
      { status: TYooKassaPaymentStatus },
      { batchId: string }
    >({
      query: (params) => ({ url: `doctor/payments/invoices/batch/${params.batchId}` }),
    }),
  }),
});

export const {
  useGetInvoicePaymentStatusQuery,
  useGetInvoicePaymentBatchStatusQuery,
} = api;
