import type { TInstructions } from "../reducers/dashboard";

export function createInstructionsSuccess() {
  return { type: "CREATE_PATIENT_INSTRUCTION" } as const;
}

export function addInstructions(json: Partial<TInstructions>) {
  return { type: "ADD_PATIENT_INSTRUCTION", json } as const;
}

export function removeInstructions(json: Partial<TInstructions>) {
  return { type: "REMOVE_PATIENT_INSTRUCTION", json } as const;
}
