import { formSubmitNotification } from "../components/notifications/form_submit_pop";
import { API_POST_NEW_MIDTERM_CORRECTION, API_PUT_PATIENT } from "../config";
import type { TPatient, TUserActionNotification } from "../reducers/dashboard";
import type { AppDispatch } from "../store";
import { patientIsSavingSuccess } from "./post_patient";
import { addUserActionNotification } from "./user_notification";

export function sendPatientCorrection(
  patient_id: TPatient["patient_id"],
  media_info,
  s3_media,
  links = null,
  stage: number,
  prescription,
  with_ct: boolean | null = null, // must be null if absent, backend validation expects it
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.form.submitted.ok",
    level: "success",
    position: "tc",
    autoDismiss: 10,
    children: formSubmitNotification(),
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.correction.create.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  const data = { stage, prescription, with_ct };

  return (dispatch: AppDispatch) => {
    fetch(API_POST_NEW_MIDTERM_CORRECTION(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error is occurred trying to order correction");
        }
        return response;
      })
      .then((response) => response.json())
      .then(() => {
        fetch(API_PUT_PATIENT(patient_id), {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ media_info, s3_media, links }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Error is occurred trying to order correction");
            }
            return response;
          })
          .then((response) => response.json())
          .then((json) => {
            dispatch(patientIsSavingSuccess(true));
            dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
            dispatch({ type: "ADD_PATIENT_SUCCESSFULL", json });
            dispatch(patientIsSavingSuccess(false));
          })
          .catch(() => {});
      })
      .catch(() => dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR)));
  };
}
