import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {sendInvoiceConfirm, doctorPaymentBatchSuccess} from '../../../../actions/invoice';
import {eraseStateProp} from '../../../../actions/dashboard';
import remoteLog from '../../../../common/logging';
import {deployedUSA} from "../../../../common/utils";


const mapStateToProps = (state) => {
    return {
        user: state.user,
        patient: state.patient
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendInvoiceConfirm: (patient_id, order_id, hash) => dispatch(sendInvoiceConfirm(patient_id, order_id, hash)),
        doctorPaymentBatchSuccess: (order_id, hash) => dispatch(doctorPaymentBatchSuccess(order_id, hash)),
        eraseStateProp: (props) => dispatch(eraseStateProp(props)),
    }
}


class PaymentInvoiceSuccess extends Component {
    componentDidCatch(e) {
        remoteLog(e, 'payment_invoice_success');
    }

    UNSAFE_componentWillMount() {
        let testUrl = this.props.match.url.match('batch');
        let secure_code = this.props.match.params.secure;
        let order_id;
        if (testUrl !== null && testUrl.length > 0) {
            if (deployedUSA() === true) {
                order_id = this.props.location.search.replace('?orderId=', '')
            } else {
                // order_id = this.props.location.search.replace('?orderId=', '').split('=')[0].replace('&orderId', '');
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                order_id = urlParams.get('orderId');
            }
            this.props.doctorPaymentBatchSuccess(order_id, secure_code);
        } else {
            order_id = this.props.location.search.replace('?orderId=', '');
            let patient_id = this.props.match.params.patient_id;
            this.props.sendInvoiceConfirm(patient_id, order_id, secure_code)
            this.props.eraseStateProp('patient');
        }
    }

    componentDidMount() {
        const { user } = this.props;
        _paq.push(['HeatmapSessionRecording::enable']);
        if (user && user.account_id) {
            _paq.push(['setUserId', user.account_id.toString()]);
        }
    }

    render() {
        return (
            <div className="page-content-wrapper">
                <div className="page-content" style={{minHeight: 584}}>
                    <div className="row">
                        <div className="col-md-8">
                            <div style={{visibility: "hidden"}} className="portlet light bordered">
                                <div className="portlet-title">
                                    <div className="caption">
                                        <i className="icon-book-open font-green"/>
                                        <span className="caption-subject font-green bold uppercase"><FormattedMessage
                                            id="pat.payments.online.payment"/></span>
                                    </div>
                                </div>
                                <div className="portlet-body">
                                    <div className="form-body">
                                        <div className="row fileupload-buttonbar">
                                            <div className="col-lg-7">
                                                <FormattedMessage id="pat.payments.course.paid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInvoiceSuccess);

