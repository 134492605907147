import clsx from "clsx";
import React from "react";

export function Portlet({
  as: Comp = "section",
  id,
  className,
  style,
  children,
}: {
  as?: "main" | "section";
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) {
  return (
    <Comp
      id={id}
      className={clsx("portlet light bordered tw-rounded-none", className)}
      style={style}
    >
      {children}
    </Comp>
  );
}

export function PortletTitle({
  as: Comp = "h1",
  id,
  iconClassName,
  children,
}: {
  as?: `h${1 | 2 | 3 | 4 | 5 | 6}`;
  iconClassName: string;
  id?: string;
  children: React.ReactNode;
}) {
  return (
    <div className="portlet-title" id={id}>
      <div className="caption">
        <i className={clsx(iconClassName, "font-green")} aria-hidden="true" />
        <Comp className="caption-subject font-green bold uppercase tw-inline">{children}</Comp>
      </div>
    </div>
  );
}

export function PortletTerm({ id, children }: { id?: string; children: React.ReactNode }) {
  return (
    <span id={id} className="tw-mb-[5px] tw-inline-block tw-max-w-full tw-font-bold">
      {children}
    </span>
  );
}
