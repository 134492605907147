import clsx from "clsx";
import React from "react";

import { Spinner } from "./spinner";

export function Pending({
  children,
  isPending,
}: {
  children: React.ReactChild;
  isPending: boolean;
}) {
  return (
    <div className={clsx({ "tw-relative": isPending })}>
      <span className={clsx({ "tw-invisible": isPending })}>{children}</span>

      {isPending ? (
        <Spinner className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
      ) : null}
    </div>
  );
}
