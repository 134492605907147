/**
 * Converts values from Instruction form to JSON
 * @param {Object} values of instruction
 */

import { deployedUSA } from "./utils";

const arraysToObj = (obj) => {
  const res = {};
  for(let pair of obj){
    const [key, value] = pair;
    if(value === 0 || value === "" || key === "" ) {
      delete obj[pair]
    } else {
      res[key] = value;
    }
  }
  return res;
};

export function convertToJSONFullRx(values: {
  arch: "1";
  avoid_locks: unknown[];
  close_all_gaps_spaces: Record<string, unknown>[];
  correction_with_ct?: boolean;
  dont_move: unknown[];
  elastics_options: Record<string, unknown>;
  extractions: unknown[];
  material: "2";
  teething: unknown[];
}) {
  if (values) {
    const prepared = { ...values };

    const extractions = [];
    const dontMove = [];
    const avoidLocks = [];
    const teething = [];
    const elastics_options = Object.keys(prepared.elastics_options).map(function(key) {
     return [key, Number(prepared.elastics_options[key])]
    });
    const close_all_gaps_spaces = prepared.close_all_gaps_spaces &&  prepared.close_all_gaps_spaces.map(function(key) {
       return Object.values(key)
    });

    const extractionsPostfix = 'extractions_';
    const dontMovePostfix = 'dont_move_';
    const avoidLocksPostfix = 'avoid_locks_';
    const teethingPostfix = 'teething_';

    Object.keys(values).forEach((key) => {
      if (key.startsWith(extractionsPostfix) && values[key] === true) {
        extractions.push(key.replace(extractionsPostfix, ''));
      } else if (key.startsWith(avoidLocksPostfix) && values[key] === true) {
        avoidLocks.push(key.replace(avoidLocksPostfix, ''));
      } else if (key.startsWith(dontMovePostfix) && values[key] === true) {
        dontMove.push(key.replace(dontMovePostfix, ''));
      } else if (key.startsWith(teethingPostfix) && values[key] === true) {
        teething.push(key.replace(teethingPostfix, ''));
      }
    });

    prepared.extractions = extractions || []
    prepared.dont_move = dontMove || []
    prepared.avoid_locks = avoidLocks || []
    prepared.teething = teething || []
    prepared.elastics_options = arraysToObj(elastics_options) || {}
    prepared.close_all_gaps_spaces = arraysToObj(close_all_gaps_spaces) || {}
    prepared.material = prepared.material !== null ? Number(prepared.material) : null
    prepared.arch = prepared.arch !== null ? Number(prepared.arch) : null

    for (let key in prepared) {
      if (key.startsWith(avoidLocksPostfix)) {
        delete prepared[key];
      } else if (key.startsWith(extractionsPostfix)) {
        delete prepared[key];
      } else if (key.startsWith(dontMovePostfix)) {
        delete prepared[key]
      } else if (key.startsWith(teethingPostfix)) {
        delete prepared[key]
      } else if (key.startsWith('null')) {
        delete prepared[key]
      }
    }

    // We pass `correction_with_ct` to the backend as a separate argument, it shouldn't be
    // inside the prescription object.
    delete prepared.correction_with_ct;
    return prepared;
  }
}


export function initializeBaseInstruction(order) {
  const initialValues = {...order};

  const arrayToObject = (arr) => {
    return {'tooth': Number(arr[0]), 'distance': (arr[arr.length - 1]).toString()}
  }

  const spaces_array = Object.entries(initialValues.close_all_gaps_spaces)
  const spaces_array_copy = [];
  spaces_array.forEach(el => spaces_array_copy.push(arrayToObject(el)))

  if (order && Object.keys(order)) {
    const dont_move = order.dont_move || null;
    const avoid_locks = order.avoid_locks || null;
    const extractions = order.extractions || null;
    const teething = order.teething || null;

    dont_move.forEach((value) => { initialValues[`dont_move_${value}`] = true; });
    avoid_locks.forEach((value) => { initialValues[`avoid_locks_${value}`] = true; });
    extractions.forEach((value) => { initialValues[`extractions_${value}`] = true; });
    teething.forEach((value) => { initialValues[`teething_${value}`] = true; });
  }

  delete initialValues.dont_move
  delete initialValues.avoid_locks
  delete initialValues.extractions
  delete initialValues.teething
  initialValues.close_all_gaps_spaces =  spaces_array_copy.length > 0 ? spaces_array_copy : [{}];
  initialValues.material = initialValues.material !== null ? order.material.toString() : null
  initialValues.arch = initialValues.arch !== null ? order.arch.toString() : null
  if (deployedUSA()) {
    initialValues.midline = initialValues.midline !== null ? order.midline.toString() : null
  }
  return initialValues;
}
