import { AcademicCapIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useHistory } from "react-router-dom";

import { getDoctorsTasks, logoutUser } from "~/actions/dashboard";
import bellIcon from "~/assets/img/bell.svg";
import shipmentsIcon from "~/assets/img/shipment_1.svg";
import { deployedRussia } from "~/common/utils";
import { PersonName } from "~/components/common/PersonName";
import { Badge } from "~/components/ui/badge";
import { useUpdateEffect } from "~/hooks/use-update-effect";
import { usePaymentsCount, useShipmentsCount, useUnpaidEventsCount } from "~/hooks/use-user";
import { useAppDispatch, useAppSelector } from "~/store";

import styles from "./profile_menu.module.css";

export function ProfileMenu() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const tour = useAppSelector((state) => state.tour);

  const numberOfUnpaidEvents = useUnpaidEventsCount();
  const numberOfPayments = usePaymentsCount();
  const numberOfShipments = useShipmentsCount();

  const doctorTasks = useAppSelector((state) => state.doctorTasks);
  const numberOfTasks = doctorTasks.patients.length;

  useEffect(() => {
    if (!doctorTasks.hasBeenLoaded) {
      dispatch(getDoctorsTasks());
    }
  }, [doctorTasks.hasBeenLoaded, dispatch]);

  useUpdateEffect(() => {
    if (!user.account_id) {
      history.replace("/login");
    }
  }, [user.account_id, history]);

  const has_signed_offer = user.has_signed_offer || (deployedRussia() && intl.locale == "en");

  async function logout() {
    await dispatch(logoutUser());
    history.push("/login");
  }

  return (
    <div className="top-menu">
      <ul className="nav navbar-nav pull-right">
        <li className="separator hide"></li>
        {has_signed_offer ? (
          <ProfileMenuBell to="/pages/events" title="" badgeCount={numberOfUnpaidEvents}>
            <AcademicCapIcon width={20} height={20} className="tw-text-[#c0cddc]" />
          </ProfileMenuBell>
        ) : null}

        {has_signed_offer ? (
          <ProfileMenuBell
            to="/pages/payments"
            title={intl.formatMessage({ id: "tooltip_payments" })}
            badgeCount={numberOfPayments}
          >
            <i className="icon-wallet" aria-hidden="true" />
          </ProfileMenuBell>
        ) : null}

        {has_signed_offer ? (
          <ProfileMenuBell
            to="/pages/tasks"
            title={intl.formatMessage({ id: "tooltip_notifications" })}
            badgeCount={numberOfTasks}
          >
            <img src={bellIcon} alt="" />
          </ProfileMenuBell>
        ) : null}

        {has_signed_offer ? (
          <ProfileMenuBell
            to="/pages/shipments"
            title={intl.formatMessage({ id: "tooltip_shipment" })}
            badgeCount={numberOfShipments}
          >
            <img style={{ width: 30 }} src={shipmentsIcon} alt="" />
          </ProfileMenuBell>
        ) : null}

        <li className="dropdown dropdown-user">
          <button
            className={clsx("dropdown-toggle", styles.dropdownToggle, { disabled: tour })}
            data-toggle="dropdown"
            data-hover="dropdown"
            data-close-others="true"
            id="top-dd-menu"
            aria-label={intl.formatMessage({ id: "profile_menu" })}
            aria-controls="profile-menu-dropdown-menu"
          >
            <span className="hideOnMobile" id="current-user">
              <PersonName person={user} isDoctor />
            </span>
            <img alt="" className="img-circle" src="/img/avatars/no-avatar.svg" />
          </button>

          <ul id="profile-menu-dropdown-menu" className="dropdown-menu dropdown-menu-default">
            {has_signed_offer ? (
              <>
                <li>
                  <NavLink to="/pages/my_account" id="top-dd-menu-my-account">
                    <i aria-hidden className="icon-user" />
                    <FormattedMessage id="top.menu.profile" />
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/pages/doctor/clinics" id="top-dd-menu-clinic-list">
                    <i aria-hidden className="icon-layers" />
                    <FormattedMessage id="top.menu.my_practices" />
                  </NavLink>
                </li>

                {deployedRussia() ? (
                  <li>
                    <NavLink to="/pages/doctor/clinic/create" id="top-dd-menu-clinic-create">
                      <i aria-hidden className="icon-plus" />
                      <FormattedMessage id="top.menu.add_practices" />
                    </NavLink>
                  </li>
                ) : null}

                <li className="divider"></li>
              </>
            ) : null}

            <li>
              <a onClick={logout} id="top-dd-menu-logout">
                <i aria-hidden className="icon-key" />
                <FormattedMessage id="top.menu.logout" />
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

function ProfileMenuBell({
  to,
  title,
  badgeCount,
  children,
}: {
  to: string;
  title: string;
  badgeCount: number;
  children?: React.ReactNode;
}) {
  return (
    <li className="dropdown">
      <NavLink
        to={to}
        title={title}
        className={clsx(
          "dropdown-toggle tw-relative",
          "tw-flex tw-h-[75px] tw-w-[60px] tw-items-center tw-justify-center tw-p-5 sm:tw-w-[75px]",
        )}
      >
        {children}

        {badgeCount > 0 ? (
          <Badge
            srOnlyText=""
            className="tw-absolute tw-right-[2px] tw-top-[15px] md:tw-right-[6px] md:tw-top-[11px]"
          >
            {badgeCount}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
}
