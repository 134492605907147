import React from "react";
import { isMobile } from "react-device-detect";

import { getTour } from "../../actions/get_tour";
import tourIcon from "../../assets/img/tour_icon.svg";
import { deployedUSA } from "../../common/utils";
import { useAppDispatch, useAppSelector } from "../../store";

export default function TourBtn() {
  const user = useAppSelector((state) => state.user);
  const tour = useAppSelector((state) => state.tour);
  const dispatch = useAppDispatch();

  if (!isMobile && deployedUSA()) {
    return (
      <button
        data-track-content
        data-content-name="call tour btn"
        style={user.account_id === undefined ? { visibility: "hidden" } : {}}
        className="btn btn_tour"
        onClick={() => {
          dispatch(getTour(!tour));
          document.body.style.overflow = "hidden";
        }}
      >
        <img src={tourIcon} alt="" />
      </button>
    );
  } else {
    return null;
  }
}
