import React from "react";
import type { RouteComponentProps } from "react-router-dom";

import { deployedUSA } from "../../../../common/utils";
import { Layout } from "../../../ui/layout";
import PaymentInvoice from "./payment_invoice";
import PaymentInvoiceUSA from "./payment_invoice_usa";

export default function PaymentInvoiceBody(props: RouteComponentProps<{ patient_id: string }>) {
  return (
    <Layout>
      <div className="row">
        <div className="col-md-8">
          {deployedUSA() ? <PaymentInvoiceUSA {...props} /> : <PaymentInvoice {...props} />}
        </div>
      </div>
    </Layout>
  );
}
