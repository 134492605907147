import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { getClinics } from "../../../actions/clinics";
import { addInstructions } from "../../../actions/patient_new_instructions";
import { Status } from "../../../common/courses";
import remoteLog from "../../../common/logging";
import { isPatient } from "../../../common/patient";
import type { TClinic } from "../../../reducers/dashboard";
import type { RootState } from "../../../store";
import Loader from "../../common/loadingInProgress";

const mapStateToProps = (state: RootState) => {
  return {
    clinics: state.clinics,
    patient: state.patient,
  };
};

const mapDispatchToProps = {
  getClinics,
  addInstruction: addInstructions,
};

type PatientUpdateDoctorInfoProps = PropsFromRedux & { intl: IntlShape } & {
  setDirty(key: string, value: string | number): void;
}

class PatientUpdateDoctorInfo extends Component<PatientUpdateDoctorInfoProps> {
  constructor(props: PatientUpdateDoctorInfoProps) {
    super(props);
    this.addComments = this.addComments.bind(this);
    this.selectClinic = this.selectClinic.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_update_doctor_info");
  }

  componentDidMount() {
    const { patient } = this.props;
    this.addComments(
      patient.logistics_comment && patient.logistics_comment.toLowerCase() !== "none"
        ? patient.logistics_comment
        : "",
    );
    if (patient.clinic && patient.clinic.clinic_id) {
      this.selectClinic(patient.clinic.clinic_id);
    }
    this.props.getClinics();
  }

  addComments(data: string) {
    this.props.addInstruction({ logistics_comment: data });
  }

  selectClinic(data: TClinic["clinic_id"] | string) {
    this.props.addInstruction({ clinic_id: parseInt(data) });
    this.props.setDirty("clinic_id", parseInt(data));
  }

  render() {
    if (isPatient(this.props.patient)) {
      const patient = this.props.patient;
      const old_instructions = this.props.patient.course.correction[0];
      if (old_instructions) {
        Object.keys(old_instructions).map((x) =>
            old_instructions[x] == "None" || old_instructions[x] == "NA"
              ? (old_instructions[x] = "")
              : null,
          )
      }

      return (
        <>
          <h3 className="block" style={{ fontWeight: "900", marginTop: "0" }}>
            <FormattedMessage id="BLOCKHEAD_DOCTOR" />
          </h3>

          <div className="form-group" id="clinic-section">
            <label
              className="control-label"
              id="validation-clinic_id"
              style={{ fontWeight: "900" }}
            >
              <FormattedMessage id="HEADER_CLINIC" />
              {this.props.patient.status == Status.UNFILLED_CASE ?  (
                <span className="required" aria-required="true">
                  *
                </span>
              ) : null}
            </label>

            {patient.status == Status.UNFILLED_CASE ? (
              <div className="radio-list">
                {this.props.clinics.map((clinic) => (
                  <label key={clinic.clinic_id}>
                    <div className="radio">
                      <input
                        type="radio"
                        id="clinic-name-value"
                        name="clinic"
                        key={clinic.clinic_id}
                        value={clinic.clinic_id}
                        onClick={(e) => this.selectClinic(e.target.value)}
                        defaultChecked={patient.clinic && patient.clinic.clinic_id == clinic.clinic_id ? true : false}
                      />
                    </div>
                    {clinic.title}
                  </label>
                ))}
              </div>
            ) : (
              <p>
                {this.props.patient.clinic ? this.props.patient.clinic.title : null}
              </p>
            )}

            <div id="form_clinic_error"></div>
          </div>
        </>
      );
    }

    return <Loader />;
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdateDoctorInfo));
