import React, { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { authenticatePatient } from "../../actions/offer";
import { deployedRussia, isDevelopment } from "../../common/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import Loader from "../common/loadingInProgress";
import PaymentInvoice from "./payment_page";

const LazyPageOffer = lazy(() => import("./page_offer"));

export default function PageContent() {
  const { patient_id, token } = useParams<{ patient_id: string; token: string }>();
  const dispatch = useAppDispatch();

  const intl = useAppSelector((state) => state.intl);
  const patient = useAppSelector((state) => state.patientData);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    dispatch(authenticatePatient(Number(patient_id), token))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  }, [dispatch, patient_id, token]);

  if (
    (window.location.protocol == "https:" || isDevelopment) &&
    deployedRussia() &&
    intl.locale == "ru"
  ) {
    const loadingElement = (
      <div style={{ display: "grid", placeItems: "center", minHeight: 584 }}>
        <Loader />
      </div>
    );

    if (isLoading) {
      return loadingElement;
    }

    if (error) {
      return <p>Ошибка: {String(error)}</p>;
    }

    if (!patient.signed) {
      return (
        <Suspense fallback={loadingElement}>
          <LazyPageOffer patient={patient} token={token} />
        </Suspense>
      );
    }

    return <PaymentInvoice patient={patient} token={token} />;
  }

  return null;
}
