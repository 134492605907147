import { API_BONUS_PATIENT, API_GET_BONUSES } from "../config";
import type { TPatient, TUserActionNotification } from "../reducers/dashboard";
import type { TUser } from "../reducers/login";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from "./user_notification";

export function getBonuses(doctor_id: TUser["account_id"]) {
  return (dispatch: AppDispatch) => {
    fetch(API_GET_BONUSES(doctor_id), { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch bonuses");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => dispatch({ type: "GET_BONUSES", json }))
      .catch(() => {});
  };
}

export function postBonusPatient(
  doctor_id: TUser["account_id"],
  patient_id: TPatient["patient_id"],
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "bonus.patient.set.successfully",
    level: "success",
    position: "tl",
    autoDismiss: 10,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "bonus.patient.set.error",
    level: "error",
    position: "tl",
    autoDismiss: 11,
  };

  return (dispatch: AppDispatch) => {
    fetch(API_BONUS_PATIENT(doctor_id, patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to set bonus patient");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: "POST_BONUS_PATIENT", json });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR)));
  };
}
