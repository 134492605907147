import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { addUserActionNotification } from "../../../actions/user_notification";
import { DN_MAP_PALMER, PLAN_LANG_OPTION_MAP } from "../../../common/constants";
import remoteLog from "../../../common/logging";
import { deployedUSA } from "../../../common/utils";
import { API_PUBLIC_LINK, PLAN_LINK_V2, PLAN_LINK_V2_RU } from "../../../config";
import type { TUserActionNotification } from "../../../reducers/dashboard";
import type { RootState } from "../../../store";
import Loader from "../../common/loadingInProgress";
import { Portlet, PortletTitle } from "../../ui/portlet";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    lang: state.intl,
    token: state.token,
  };
};

const mapDispatchToProps = {
  addUserActionNotification,
};

type Patient3DPlanProps = PropsFromRedux;

type Patient3DPlanState = {
  public_link: string | null;
  showLoader: boolean;
  canSubmit: boolean;
};

class Patient3DPlan extends Component<Patient3DPlanProps, Patient3DPlanState> {
  link: HTMLTextAreaElement | null = null;

  constructor(props: Patient3DPlanProps) {
    super(props);
    this.state = {
      public_link: null,
      showLoader: false,
      canSubmit: true,
    };
    this.open3dPlan = this.open3dPlan.bind(this);
    this.sharePublicLink = this.sharePublicLink.bind(this);
    this.sharePublicLinkNotification = this.sharePublicLinkNotification.bind(this);
    this.copyPublicLink = this.copyPublicLink.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_3d_plan");
  }

  open3dPlan() {
    const { user } = this.props;
    let langLocale = this.props.lang.locale;
    if (deployedUSA() === true) {
      langLocale = PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] || PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(PLAN_LINK_V2(this.props.patient.patient_id, this.props.token, langLocale))
    } else {
      window.open(PLAN_LINK_V2_RU(this.props.patient.patient_id, this.props.token, langLocale))
    }
  }

  sharePublicLink() {
    this.setState({ showLoader: true });
    this.setState({ canSubmit: false });
    const { patient } = this.props;

    if (patient && Object.keys(patient).length > 0) {
      fetch(
        API_PUBLIC_LINK(patient.patient_id),
        { credentials: "include" }
      )
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        return response;
      })
      .then(response => response.json())
      .then((response) => {
        this.setState({ public_link: response, showLoader: false });
        this.sharePublicLinkNotification();
      }).catch(() => {
        const NOTIFICATION_LINK: TUserActionNotification = {
          message: "public.plan.error",
          level: "error",
          position: "tl",
          autoDismiss: 4,
        };
        this.props.addUserActionNotification(NOTIFICATION_LINK);
        this.setState({ public_link: null, showLoader: false, canSubmit: true });
      })
    }
  }

  sharePublicLinkNotification() {
    if (this.props.user.email_notify || this.props.user.telegram_notify) {
      const NOTIFICATION_LINK: TUserActionNotification = {
        message: "",
        level: "success",
        position: "tl",
        autoDismiss: 4,
      };

      if (this.props.user.email_notify && this.props.user.telegram_notify) {
        NOTIFICATION_LINK.message = "notify.link_tg_email";
      } else if (this.props.user.email_notify) {
        NOTIFICATION_LINK.message = "notify.link_email";
      } else if (this.props.user.telegram_notify) {
        NOTIFICATION_LINK.message = "notify.link_tg";
      }
      this.props.addUserActionNotification(NOTIFICATION_LINK);
    }
  }

  copyPublicLink() {
    const NOTIFICATION_LINK_COPY: TUserActionNotification = {
      message: "notify.link_copy",
      level: "success",
      position: "tl",
      autoDismiss: 4,
    };
    this.props.addUserActionNotification(NOTIFICATION_LINK_COPY);
  }

  render() {
    return (
      <Portlet>
        <PortletTitle as="h2" iconClassName="icon-docs">
          <FormattedMessage id="pat.plan.header" />
        </PortletTitle>
        <div className="portlet-body">
            <div className="form-group">
              <div className="form-block">
                <button
                  onClick={() => this.open3dPlan()}
                  className="btn green"
                  id="test-btn">
                  <FormattedMessage id="pat.plan.button" />
                </button>
              </div>
            </div>
            {!this.state.public_link &&
              <div className="form-group">
                <div className="form-block">
                  <button
                    type="button"
                    className="btn green"
                    onClick={() => this.sharePublicLink()}
                    disabled={!this.state.canSubmit}>
                    <FormattedMessage id="pat.plan.link"/>
                  </button>
                </div>
              </div>
            }
            {this.state.showLoader && <Loader/>}
            {this.state.public_link &&
              <div>
                <div className="form-group">
                  <div className="form-block">
                    <button className="btn green">
                      <CopyToClipboard text={this.state.public_link ? this.state.public_link : ''}
                        onCopy={() => this.copyPublicLink()}>
                        <span><FormattedMessage id="pat.plan.copy_link" /></span>
                      </CopyToClipboard>
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-block">
                    <textarea
                      className="form-control"
                      readOnly
                      rows={3}
                      value={this.state.public_link}
                      ref={input => this.link = input}
                      onClick={() => this.link.select()}
                    />
                  </div>
                </div>
              </div>
            }
        </div>
      </Portlet>
    );
	}
}

const connector = connect(mapStateToProps,mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Patient3DPlan);
