import type { PaymentOption } from "../common/constants";
import { API_COURSE_INSTALLMENT_GET } from "../config";
import type { AppDispatch } from "../store";

export function getCourseInstallment() {
  return (dispatch: AppDispatch) => {
    fetch(API_COURSE_INSTALLMENT_GET, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed loading { GET_COURSE_INSTALLMENT_FAILURE }: " + response.statusText,
          );
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => dispatch({ type: "GET_COURSE_INSTALLMENT_SUCCESS", json }));
  };
}

export function selectCourseAction(data: PaymentOption[]) {
  return { type: "SELECT_COURSE", data } as const;
}

export function courseSelectedAction(data: boolean) {
  return { type: "COURSE_SELECTED_TRIGGER", data } as const;
}
