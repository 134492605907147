import React, { useEffect } from "react";
import { FormattedMessage, type MessageDescriptor, useIntl } from "react-intl";

import {
  DeliveryDestination,
  getDeliveries,
  trackingLink,
  UPS_TRACK_LOCALE_MAP,
} from "../../../actions/deliveries";
import { isChildrenShortCourse } from "../../../common/courses";
import { getLastCorrection } from "../../../common/patient";
import type { TDelivery } from "../../../reducers/dashboard";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FormatDate } from "../../common/FormatDate";
import { Portlet, PortletTitle } from "../../ui/portlet";

export function PatientDeliveries() {
  const dispatch = useAppDispatch();

  const deliveries = useAppSelector((state) => state.delivery.deliveries);
  const patient = useAppSelector((state) => state.patient);

  useEffect(() => {
    if (patient && patient.patient_id) {
      dispatch(getDeliveries(patient.patient_id));
    }
  }, [dispatch, patient]);

  const alignerDeliveries = deliveries.filter(
    (delivery) => delivery.destination == DeliveryDestination.CLINIC,
  );
  const impressionShipments = deliveries.filter(
    (delivery) => delivery.destination == DeliveryDestination.LABORATORY,
  );
  const upsPrivilege = getLastCorrection(patient).order_options.ups_deliveries_privilege;

  return (
    <>
      <DeliveryTable
        deliveries={alignerDeliveries}
        header="pat.block.deliveries.shipments.aligners"
        showShipmentDateColumn={false}
      />

      {upsPrivilege ? (
        <DeliveryTable
          deliveries={impressionShipments}
          header="pat.block.deliveries.deliveries.impressions"
        />
      ) : null}
    </>
  );
}

function DeliveryTable({
  deliveries,
  header,
  showShipmentDateColumn = true,
}: {
  deliveries: TDelivery[];
  header: MessageDescriptor["id"];
  showShipmentDateColumn?: boolean;
}) {
  const intl = useIntl();
  const patient = useAppSelector((state) => state.patient);

  const upsLocale = UPS_TRACK_LOCALE_MAP[intl.locale];
  const isChildrenShort = isChildrenShortCourse(patient.course.course_id);

  return (
    <Portlet>
      <PortletTitle as="h2" iconClassName="icon-envelope">
        <FormattedMessage id={header} />
      </PortletTitle>

      <div className="portlet-body">
        <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
          <div className="table-scrollable">
            <table className="table table-bordered table-hover dataTable no-footer" role="grid">
              <thead>
                <tr role="row">
                  <th style={{ width: 150 }}>
                    <FormattedMessage id="pat.block.deliveries.waybills" />
                  </th>

                  {showShipmentDateColumn ? (
                    <th style={{ width: 79 }}>
                      <FormattedMessage id="pat.block.deliveries.shipment.date" />
                    </th>
                  ) : null}

                  <th style={{ width: 79 }}>
                    <FormattedMessage
                      id={isChildrenShort ? "pat.block.payment.payments" : "pat.block.right.correction"}
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {deliveries.map((delivery, index) => (
                  <tr
                    key={delivery.track_number}
                    className={"clickable-row " + (index % 2 ? "even" : "odd")}
                    role="row"
                  >
                    <td>
                      <a
                        href={trackingLink(delivery.track_number, delivery.type, upsLocale)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {delivery.track_number}
                      </a>
                    </td>

                    {showShipmentDateColumn ? (
                      <td>
                        <FormatDate value={delivery.pickup_date} date />
                      </td>
                    ) : null}

                    <td>
                      {delivery.correction_number > 0 ? (
                        <>
                          <FormattedMessage
                            id={isChildrenShort ? "pat.block.right.additional_course" : "pat.block.right.correction"}
                          />{" "}
                          {delivery.correction_number}
                        </>
                      ) : (
                        <FormattedMessage id="pat.block.right.general.treatment" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Portlet>
  );
}
