import { useEffect } from "react";

import { getDoctorsPays } from "~/actions/dashboard";
import { getAllShipments } from "~/actions/deliveries";
import { useGetUnpaidEventsQuery } from "~/components/doctor/events/events-api";
import { useAppDispatch, useAppSelector } from "~/store";

export function usePaymentsCount(): number {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const doctorPays = useAppSelector((state) => state.doctorPays);
  const numberOfPayments = doctorPays.patients.length;

  useEffect(() => {
    // When you log out, this component is rerendered, and doctorTasks.hasBeenLoaded is false,
    // so the request fires.
    // That's why we check if user is authenticated before trying to load the payments.
    if (user.account_id && !doctorPays.hasBeenLoaded) {
      dispatch(getDoctorsPays());
    }
  }, [user.account_id, doctorPays.hasBeenLoaded, dispatch]);

  return numberOfPayments;
}

export function useShipmentsCount(): number {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const shipments = useAppSelector((state) => state.shipments);
  const numberOfShipments = shipments.data.length;

  useEffect(() => {
    // When you log out, this component is rerendered, and shipments.hasBeenLoaded is false,
    // so the request fires and we gen an error notification.
    // That's why we check if user is authenticated before trying to load the shipments.
    if (user.account_id && !shipments.hasBeenLoaded) {
      dispatch(getAllShipments());
    }
  }, [user.account_id, shipments.hasBeenLoaded, dispatch]);

  return numberOfShipments;
}

export function useUnpaidEventsCount(): number {
  const unpaidEventsQuery = useGetUnpaidEventsQuery();
  const numberOfUnpaidEvents = unpaidEventsQuery.isSuccess
    ? Object.values(unpaidEventsQuery.data).reduce((a, b) => a + b, 0)
    : 0;

  return numberOfUnpaidEvents;
}
