import { paymentNotification } from "../components/notifications/payment_notifications";
import type { PatientsPayListState } from "../components/patient/patients_pay_list";
import { API_DOCTOR_PAYMENT_INVOICE_BATCH, API_INVOICE, API_PATIENT_PAYMENT } from '../config';
import type { TPatient } from "../reducers/dashboard";
import type { AppDispatch } from "../store";
import { addUserActionNotification } from './user_notification';

const GET_PATIENT_PAYMENT = "GET_PATIENT_PAYMENT"
export const GET_PATIENT_PAYMENT_SUCCESS = "GET_PATIENT_PAYMENT_SUCCESS"
const GET_PATIENT_PAYMENT_ERROR = "GET_PATIENT_PAYMENT_ERROR"

export function sendInvoiceRequest(patient_id: TPatient["patient_id"], amount: number) {
  return (dispatch: AppDispatch) => {
    fetch(API_INVOICE(patient_id), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        amount: amount,
      })
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Failed loading { GET_INVOICE_DATA }: " + response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: 'GET_INVOICE_DATA', json })
      })
  }
}

export function sendInvoiceConfirm(patient_id, order_id, hash) {

    const NOTIFICATION_MSG_OK = {
        message: 'pat.payments.course.paid',
        level: 'success',
        position: 'tc',
        autoDismiss: '15',
        children: paymentNotification()
    };

    return (dispatch) => {
        fetch(API_INVOICE(patient_id), {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                order_id: order_id,
                hash: hash
            })
        })
            .then((response) => {
                if (!response.ok) {
                    console.error("Failed loading { GET_INVOICE_DATA }: " + response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then(json => {
                dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
            })
    }
}


/**
 *
 * @param {String} patient_id patients identifier
 * @param {String} amount amount to pay
 */
export function getAuthorizeNetFormToken(patient_id, amount, payment_account) {

    return (dispatch) => {
        fetch(API_INVOICE(patient_id), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                amount: amount,
                payment_account: payment_account
            })
        })
            .then((response) => {
                if (!response.ok) {
                    console.error("Failed loading { GET_INVOICE_DATA }: " + response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((json) => {
                dispatch({type: 'GET_INVOICE_DATA', json})
            })
    }
}

export function sendPatientPaymentRequest(
  patient_id: TPatient["patient_id"],
  amount: unknown,
  token: string
) {
  return (dispatch: AppDispatch) => {
    fetch(API_PATIENT_PAYMENT(patient_id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount,
        token: token,
        patient_id: patient_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(addUserActionNotification({ ...NOTIFICATION_MSG_ERROR }));
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: GET_PATIENT_PAYMENT_SUCCESS, fields: json });
      });
  };
}

export function sendPatientPaymentConfirm(patient_id, token, order_id, hash) {

    return (dispatch) => {
        fetch(API_PATIENT_PAYMENT(patient_id), {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                patient_id, token, order_id, hash
            })
        })
            .then((response) => {
                if (!response.ok) {
                    console.error("Failed " + response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
    }
}

export function doctorPaymentBatch(
  patients: PatientsPayListState["patients"],
  total: number,
  payment_account: boolean
) {
    return (dispatch: AppDispatch) => {
        fetch(API_DOCTOR_PAYMENT_INVOICE_BATCH, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                patients: patients,
                total: total,
                payment_account: payment_account
            })
        })
            .then((response) => {
                if (!response.ok) {
                    console.error("Failed loading { GET_INVOICE_DATA }: " + response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((json) => {
                dispatch({type: 'GET_INVOICE_DATA', json})
            })
    }
}

export function doctorPaymentBatchSuccess(order_id, hash) {

    const NOTIFICATION_MSG_OK = {
        message: 'pat.payments.course.paid',
        level: 'success',
        position: 'tc',
        autoDismiss: '15',
        children: paymentNotification()
    };

    return (dispatch) => {
        fetch(API_DOCTOR_PAYMENT_INVOICE_BATCH, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                order_id: order_id,
                hash: hash
            })
        })
            .then((response) => {
                if (!response.ok) {
                    console.error("Failed loading { GET_INVOICE_DATA }: " + response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then(() => {
                dispatch(addUserActionNotification(NOTIFICATION_MSG_OK))
            })
    }
}


