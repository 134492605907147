import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';

import { Case, isChildrenShortCourse } from '../../../common/courses';
import remoteLog from '../../../common/logging';
import { deployedUSA } from '../../../common/utils';
import type { RootState } from '../../../store';
import { FormatDate } from "../../common/FormatDate";
import { PersonName } from "../../common/PersonName";
import ProbablyFormattedMessage from '../../probably_formatted_message';
import { Portlet, PortletTerm, PortletTitle } from '../../ui/portlet';

const mapStateToProps = (state: RootState) => {
  return {
    patient: state.patient,
    lang: state.intl,
  };
};

type PatientIdProps = PropsFromRedux & { intl: IntlShape };

class PatientId extends Component<PatientIdProps> {
  constructor(props: PatientIdProps) {
    super(props);

    this.showDates = this.showDates.bind(this);
    this.fm = this.fm.bind(this);
    this.renderCourseName = this.renderCourseName.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, 'patient_id');
  }

  fm(id: MessageDescriptor["id"]) {
    if (deployedUSA()) {
      return this.props.intl.formatMessage({ id: id });
    }
  }

  showDates() {
    if (this.props.patient && this.props.patient.course && this.props.patient.course.correction.length > 0) {
      const lastCorrection = this.props.patient.course.correction.slice(-1)[0]!;
      const start_date = lastCorrection.start_date;
      const plan_date = lastCorrection.plan_date;
      const steps_count_total = lastCorrection.steps_count_total;
      const approval_timestamp = lastCorrection.approval_timestamp;
      const deliveryEstimate = this.props.patient.delivery_estimate;
      const deliveryAdditionalEstimate = this.props.patient.delivery_additional_estimate;
      const deliveryRetainersEstimate = this.props.patient.delivery_retainers_estimate;

      return (
        <div>
          {this.props.patient.course.correction.map((c, index) =>
            index > 0 ?
              <div key={index}>
                <label>
                  <strong>
                    <FormattedMessage
                      id={
                        isChildrenShortCourse(this.props.patient.course.course_id) ?
                          "pat.block.right.additional_course" :
                          "pat.block.right.correction"
                      }
                    />
                    <span> {index}</span>
                  </strong>
                </label>
                <p>
                  <FormatDate value={c.timestamp} date />
                </p>
              </div>
              : null
          )}
          {start_date !== null ? (
              <>
                <PortletTerm>
                  <FormattedMessage id="pat.block.right.start_date" />
                </PortletTerm>
                <p><FormatDate value={start_date} date /></p>
              </>
           ) : null}
          {plan_date !== null ? (
              <>
                <PortletTerm>
                  <FormattedMessage id="pat.block.right.plan_date" />
                </PortletTerm>
                <p><FormatDate value={plan_date} date /></p>
              </>
           ) : null}
          {approval_timestamp !== null ? (
              <div data-toggle="tooltip" title={this.fm("tooltip_3d_plan_approval_date")}>
                <PortletTerm>
                  <FormattedMessage id="pat.block.right.plan_approval" />
                </PortletTerm>
                <p><FormatDate value={approval_timestamp} date /></p>
              </div>
           ) : null}
          {deployedUSA() === true && deliveryEstimate !== null
            ? (
              <div>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.aligners.eta" /> </strong>
                  <p><FormatDate value={deliveryEstimate} date /></p>
                </label>
              </div>
            ) : null
          }
          {deployedUSA() === true && deliveryAdditionalEstimate !== null
            ? (
              <div>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.additional.eta" /> </strong>
                  <p><FormatDate value={deliveryAdditionalEstimate} date /></p>
                </label>
              </div>
            ) : null
          }
          {deployedUSA() === true && deliveryRetainersEstimate !== null
            ? (
              <div>
                <label>
                  <strong> <FormattedMessage id="pat.block.right.retainers.eta" /> </strong>
                  <p><FormatDate value={deliveryRetainersEstimate} date /></p>
                </label>
              </div>
            ) : null
          }
          {steps_count_total !== 'None' ? (
              <>
                <PortletTerm>
                  <FormattedMessage id="p.patient.correction.stages" />
                </PortletTerm>
                <p>{steps_count_total}</p>
              </>
          ) : null}
        </div>
      )
    }
    return null
  }

  renderCourseName() {
    const { patient } = this.props;
    const course_id = patient.course.course_id;
    const course = patient.course.course;

    return (
      <p>
        {course_id === Case.CBCT ? (
          <img src="/img/deepcbct.png" alt="cbct-icon" style={{ width: '80px', height: '42px', display: 'block' }} />
        ) : (
          <span style={{ display: 'block', color: '#26a1ab', fontWeight: '700' }}>
            <ProbablyFormattedMessage id={`${course}_USA`} />
          </span>
        )
        }
        {!(course === 'C_3D_PLAN' || course === 'C_RETAINERS') ? <ProbablyFormattedMessage id={`${course}_COMMENT_USA`} /> : null}
      </p>
    )
  }

  render() {
    const warranty_end_date = this.props.patient.warranty_end_date;
    const bonus = this.props.patient && this.props.patient.bonus === true;
    const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id: id });
    const course = this.props.patient.course.course;

    return (
      <Portlet id="patient-info-side-block">
        <PortletTitle as="h2" iconClassName="icon-user" id="patient-info-side-block-name">
          <FormattedMessage id="pat.block.right.header" />&nbsp;&nbsp;
          {bonus === true
            ? <img style={{ width: '2rem' }} src="/img/bonus.svg" title={fm("bonus.patient")} alt="" />
            : null
          }
        </PortletTitle>

        <div className="portlet-body" id="patient-info-side-block-body">
          <PortletTerm id="patient-info-side-block-id-label">
            <FormattedMessage id="pat.block.right.id" />
          </PortletTerm>
          <p id="patient-info-side-block-id-value">
            {this.props.patient.patient_id}
          </p>

          <PortletTerm id="patient-info-side-block-name-label">
            <FormattedMessage id="pat.block.right.name" />
          </PortletTerm>
          <p id="patient-info-side-block-name-value">
            <PersonName person={this.props.patient} />
          </p>

          <PortletTerm id="patient-info-side-block-main-course-submission-date-label">
            <FormattedMessage id="pat.block.right.instructions" />
          </PortletTerm>
          <p id="patient-info-side-block-main-course-submission-date-value">
            <FormatDate value={this.props.patient.timestamp} date />
          </p>

          {warranty_end_date ? (
              <>
                <PortletTerm id="patient-info-side-block-main-course-warranty-label">
                  <FormattedMessage id="pat.block.right.warranty_end_date" />
                </PortletTerm>
                <p id="patient-info-side-block-main-course-warranty-value">
                  <FormatDate value={warranty_end_date} date />
                </p>
              </>
          ) : null}

          {this.showDates()}
          {deployedUSA() && course !== "None" ? (
              <div>
                <label className="control-label" id="course-label">
                  <strong>
                    <FormattedMessage id="HEADER_INSTRUCTIONS" />
                  </strong>
                </label>
                {this.renderCourseName()}
              </div>
           ) : null}
        </div>
      </Portlet>
    )
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientId));
