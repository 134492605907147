import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { getLastCorrection } from "../../../common/patient";
import { deployedRussia } from "../../../common/utils";
import type { TPatient } from "../../../reducers/dashboard";
import { FormatNumber } from "../../common/FormatNumber";
import { Portlet, PortletTerm, PortletTitle } from "../../ui/portlet";

export function PatientPayment({ patient }: { patient: TPatient }) {
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const toggleDivRef = useRef<HTMLDivElement>(null);

  function togglePayment() {
    setShowPaymentInfo(prevShowPaymentInfo => !prevShowPaymentInfo);
    $(toggleDivRef.current).slideToggle();
  }

  const currentPayments = patient.total_payments;
  const lastCorrection = getLastCorrection(patient);

  return (
    <Portlet id="payment-info-side-block">
      <PortletTitle as="h2" iconClassName="icon-wallet" id="payment-info-side-block-name">
        <FormattedMessage id="pat.block.payment.header" />
      </PortletTitle>

      <a id="payment-info-side-show-button" onClick={togglePayment}>
        <FormattedMessage id={showPaymentInfo ? "general.payment.close" : "general.payment.show"} />
      </a>

      <div
        className="portlet-body"
        ref={toggleDivRef}
        style={{ display: "none" }}
        id="payment-info-side-block-body"
      >
        <PortletTerm id="payment-status-side-block-label">
          <FormattedMessage id="pat.block.payment.status" />
        </PortletTerm>

        <p id="payment-status-side-block-value">
          {lastCorrection.payment_status ? (
            <FormattedMessage id={lastCorrection.payment_status} />
          ) : null}
        </p>

        {deployedRussia() ? (
          <div>
            <PortletTerm id="paid-info-side-block-label">
              <FormattedMessage id="pat.block.payment.received" />
            </PortletTerm>
            <p id="paid-info-side-block-value">
              <FormatNumber value={currentPayments.paid} />{" "}
              <FormattedMessage id="pat.block.payment.currency" />
            </p>

            <PortletTerm id="payment-due-side-block-label">
              <strong>
                <FormattedMessage id="pat.block.payment.total" />
              </strong>
            </PortletTerm>
            <p id="payment-due-side-block-value">
              <FormatNumber value={currentPayments.total} />{" "}
              <FormattedMessage id="pat.block.payment.currency" />
            </p>
          </div>
        ) : (
          <div>
            <PortletTerm id="paid-info-side-block-label">
              <FormattedMessage id="pat.block.payment.currency.usa" />{" "}
              <FormattedMessage id="pat.block.payment.received" />{" "}
            </PortletTerm>
            <p id="paid-info-side-block-value">
              <FormattedMessage id="pat.block.payment.currency.usa" />{" "}
              <FormatNumber value={currentPayments.paid} />
            </p>

            <PortletTerm id="payment-due-side-block-label">
              <FormattedMessage id="pat.block.payment.currency.usa" />{" "}
              <FormattedMessage id="pat.block.payment.total" />
            </PortletTerm>
            <p id="payment-due-side-block-value">
              <FormattedMessage id="pat.block.payment.currency.usa" />{" "}
              <FormatNumber value={currentPayments.total} />
            </p>
          </div>
        )}
      </div>
    </Portlet>
  );
}
