import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { PREDICT_SUPPORT_EMAIL, PREDICT_SUPPORT_PHONE } from "../../common/constants";
import { deployedUSA } from "../../common/utils";
import { useDocumentTitle } from "../../hooks/use-document-title";
import { useAppSelector } from "../../store";
import { Layout } from "../ui/layout";
import { Portlet, PortletTitle } from "../ui/portlet";

function clipboardCopy(value: string) {
  /* Copy the text inside the text field */
  void navigator.clipboard.writeText(value.replace(/\s/g, ""));
}

export default function SupportPage() {
  const intl = useIntl();
  useDocumentTitle(intl.formatMessage({ id: "support.page.header" }));
  const user = useAppSelector((state) => state.user);

  _paq.push(["HeatmapSessionRecording::enable"]);
  if (user && user.account_id) {
    _paq.push(["setUserId", user.account_id.toString()]);
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-md-12">
          <Portlet as="main">
            <PortletTitle iconClassName="icon-call-out">
              <FormattedMessage id="support.page.header" />
            </PortletTitle>

            <div className="portlet-body" style={{ textAlign: "center" }}>
              {deployedUSA() ? (
                <>
                  <label className="control-label" style={{ fontWeight: "700", fontSize: "14px" }}>
                    <FormattedMessage id="support.page.contacts.header" />
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <a href={`tel:${PREDICT_SUPPORT_PHONE}`}>
                        <img src="/img/iconPhone.png" width="100" height="100" alt="" />
                      </a>
                      <a
                        href={`tel:${PREDICT_SUPPORT_PHONE}`}
                        className="contact_link"
                        onClick={() => clipboardCopy(PREDICT_SUPPORT_PHONE)}
                        title={intl.formatMessage({ id: "click.to.copy" })}
                      >
                        {PREDICT_SUPPORT_PHONE}
                      </a>
                      <div>
                        <i>
                          <FormattedMessage id="support.page.toll.free" />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <a href={`mailto:${PREDICT_SUPPORT_EMAIL}`}>
                        <img src="/img/iconEmail.png" width="100" height="100" alt="" />
                      </a>
                      <a
                        href={`mailto:${PREDICT_SUPPORT_EMAIL}`}
                        className="contact_link"
                        onClick={() => clipboardCopy(PREDICT_SUPPORT_EMAIL)}
                        title={intl.formatMessage({ id: "click.to.copy" })}
                      >
                        {PREDICT_SUPPORT_EMAIL}
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div className="row" style={{ alignContent: "center", marginTop: "15px" }}>
                    <p>
                      <FormattedMessage id="support.page.zoom.call.title" />
                    </p>
                    <a
                      href={process.env.CALENDLY_LINK}
                      target="_black"
                      rel="noopener noreferrer"
                      data-toggle="tooltip"
                      title={intl.formatMessage({
                        id: "support.page.onbording.btn.tooltip",
                      })}
                      className="btn green"
                    >
                      <FormattedMessage id="support.page.onbording.btn" />
                    </a>
                  </div>
                  <hr />
                  <div className="row" style={{ alignContent: "center", marginTop: "15px" }}>
                    <i>
                      <FormattedMessage id="support.page.operating.hours" />
                    </i>
                  </div>
                </>
              ) : (
                <label className="control-label" style={{ fontWeight: "700", fontSize: "14px" }}>
                  <FormattedMessage id="not.found" />
                </label>
              )}
            </div>
          </Portlet>
        </div>
      </div>
    </Layout>
  );
}
