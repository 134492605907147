import invariant from "tiny-invariant";
import { isMatching, P } from "ts-pattern";

import type { TCorrection, TPatient } from "../reducers/dashboard";

export function getLastCorrection(patient: TPatient): TCorrection {
  const lastCorrection = patient.course.correction[patient.course.correction.length - 1];
  // patient should always have at least one correction, so this should never raise an error
  invariant(lastCorrection, `patient_id: ${patient.patient_id} - no last correction found`);
  return lastCorrection
}

const patientPattern = {
  patient_id: P.number,
  course: {
    course_id: P.union(P.number, "None"),
  },
};

/**
  Use this function to determine if value is a patient object.
 */
export function isPatient(value: unknown): value is TPatient {
  return isMatching(patientPattern, value);
}
