import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  DeliveryDestination,
  getShipment,
  pickup,
  trackingLink,
  UPS_TRACK_LOCALE_MAP
} from '../../../actions/deliveries';
import remoteLog from '../../../common/logging';
import { getLastCorrection } from '../../../common/patient';
import Loader from '../../../components/common/loadingInProgress';
import { API_PATIENT_IMAGE } from '../../../config';
import type { TDelivery } from '../../../reducers/dashboard';
import type { RootState } from '../../../store';
import { FormatDate } from '../../common/FormatDate';
import { Portlet, PortletTitle } from '../../ui/portlet';

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.intl,
    patient: state.patient,
    deliveries: state.delivery.deliveries,
    loading: state.delivery.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ pickup, getShipment }, dispatch);
};

type PatientDeliveryProps = PropsFromRedux & { intl: IntlShape };

class PatientDelivery extends Component<PatientDeliveryProps> {
  componentDidCatch(e: Error) {
    remoteLog(e, 'patient_delivery');
  }

  getLatestDeliveryForPickup() {
    const { deliveries } = this.props;
    if (Array.isArray(deliveries) && deliveries.length > 0) {
      const filtered = deliveries.filter(
        delivery => (
          delivery.track_number && !delivery.prn && !delivery.called_by_phone && delivery.destination === DeliveryDestination.LABORATORY
        )
      );
      if (filtered.length) {
        return filtered[0];
      }
    }
    return null;
  }

  getLatestPickedUpDelivery() {
    const { deliveries } = this.props;
    if (deliveries && Array.isArray(deliveries) && deliveries.length) {
      const filtered = deliveries.filter(
        delivery => delivery.track_number && (delivery.prn !== null || delivery.called_by_phone)
          && delivery.destination === DeliveryDestination.LABORATORY
      );
      if (filtered.length) {
        return filtered[0];
      }
    }
    return null;
  }

  callCourierBtn(delivery: TDelivery | null | undefined, pickupDeliveryExists: boolean) {
    const { patient, getShipment, pickup, loading } = this.props;

    if (delivery !== null) {
      return (
        <div className="form-group">
          <div className="form-block">
            <button
              className="btn green"
              id="add-comment-btnnn delivery-call-courier-btn"
              onClick={() => pickup(patient.patient_id, delivery.delivery_id)}
              disabled={loading}
            >
              <FormattedMessage id="pat.deliveries.button.callACourier" />
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="form-group">
          <div className="form-block">
            <button
              className="btn green"
              id="add-comment-btnnn delivery-get-new-waybill-btn"
              onClick={() => getShipment(patient.patient_id)}
              disabled={loading}
              style={{ whiteSpace: "normal" }}
            >
              <FormattedMessage id={!pickupDeliveryExists ? "pat.deliveries.button.getNewWaybill" : "pat.deliveries.button.getWaybill"} />
            </button>
          </div>
        </div>
      )
    }
  }

  renderPickedUp(pickedup_delivery: TDelivery | null | undefined) {
    const { patient, lang, intl } = this.props;
    const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale];

    if (pickedup_delivery) {
      return (
          <div>
            <br />
            <label><strong> <FormattedMessage id="pat.block.deliveries.current.pickups" /> </strong></label><br /><br />
            <label><strong>{pickedup_delivery.track_number}</strong></label>&nbsp;&nbsp;
            <a
              href={trackingLink(pickedup_delivery.track_number, pickedup_delivery.type, upsLocale)}
              rel="noopener noreferrer"
              target="_blank"
            >
                <i
                  className="icon-info font-green"
                  title={intl.formatMessage({ id: "pat.block.deliveries.ups.site.track" })}
                />
            </a><br /><br />
            <a href={`${API_PATIENT_IMAGE(patient.patient_id, pickedup_delivery.filename)}`} rel="noopener noreferrer" target="_blank" id="delivery-print-waybill-link">
              <b id="delivery-print-waybill"><FormattedMessage id="pat.block.deliveries.see.waybill"/></b>
            </a><br /><br />
            {!pickedup_delivery.called_by_phone
              ? (
                <p>
                  <label id="delivery-date-label"><strong><FormattedMessage id="pat.block.deliveries.estimated.pickup_date" /></strong></label>
                  <br /><FormatDate id="delivery-date-value" value={pickedup_delivery.pickup_date} date/>
                </p>
              ) : null
            }
          </div>
      );
    }

    return null;
  }

  renderNoDelivery(
    pickedup_delivery: TDelivery | null | undefined,
    canOrderDelivery: boolean,
    shouldFillDeliveryInfo: boolean
  ) {
    if (!pickedup_delivery && canOrderDelivery && !shouldFillDeliveryInfo) {
      return <FormattedMessage id="pat.deliveries.button.noWaybills" />;
    } else if (shouldFillDeliveryInfo === true) {
      return <FormattedMessage id="pat.deliveries.contact.to.manager" />;
    }
    return null
  }

  render () {
    const { patient, deliveries, loading } = this.props;
    const delivery = this.getLatestDeliveryForPickup();
    const pickedup_delivery = this.getLatestPickedUpDelivery();
    const pickupDeliveryExists = pickedup_delivery === null;

    const lastCorrection = getLastCorrection(patient);
    const canOrderDelivery = lastCorrection.order_options.can_order_delivery;
    const shouldFillDeliveryInfo = lastCorrection.order_options.should_fill_delivery_info;
    const upsPrivilege = lastCorrection.order_options.ups_deliveries_privilege;

    const showOtherDeliveriesText = Array.isArray(deliveries) && deliveries.length > 0;

    if (upsPrivilege) {
      return (
        <Portlet id="deliveries-side-block">
          <PortletTitle as="h2" iconClassName="icon-envelope" id="deliveries-side-block-name">
            <NavLink className="open" to={`/pages/patient/${patient.patient_id}/deliveries`} style={{ fontSize: 16 }}>
              <FormattedMessage id="pat.block.impression.delivery.header" />
            </NavLink>
          </PortletTitle>

            <div className="portlet-body" id="deliveries-side-block-body">
              {this.renderPickedUp(pickedup_delivery)}
              <div>
                {delivery !== null && canOrderDelivery === true
                  ? (
                    <div>
                      <label id="delivery-tracking-numbe-label">
                        <strong id="delivery-tracking-number-value"> <FormattedMessage id="pat.block.deliveries.waybills" /> </strong>
                      </label><br />
                      {delivery.track_number}<br />
                      <a href={`${API_PATIENT_IMAGE(patient.patient_id, delivery.filename)}`} rel="noopener noreferrer" target="_blank" id="delivery-print-waybill-link" >
                        <span id="delivery-print-waybill"><FormattedMessage id="pat.block.deliveries.see.waybill"/></span>
                      </a>
                    </div>
                  ) : (
                    <p id="no-delivery-data-alert">{this.renderNoDelivery(pickedup_delivery, canOrderDelivery, shouldFillDeliveryInfo)}</p>
                  )
                }
              </div>
            </div>
            <div>
              <br />
              {canOrderDelivery ? this.callCourierBtn(delivery, pickupDeliveryExists) : null}
            </div>
            {loading ? <Loader /> : null}
            <div>
              <br />
              {showOtherDeliveriesText ? (
                <NavLink className="open" to={`/pages/patient/${patient.patient_id}/deliveries`}>
                  <span id="deliveries-side-block-other">
                    <i><FormattedMessage id="pat.block.impression.delivery.all" /></i>
                  </span>
                </NavLink>
              ) : null}
            </div>
        </Portlet>
      )
    }

    return null;
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientDelivery));
