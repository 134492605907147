import { intlReducer } from "react-intl-redux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import { api } from "../api";
import { isEnglishLocale } from "../common/locale";
import { devTools } from "../dev-tools/reducer";
import englishMessages from "../locale/en";
import russianMessages from "../locale/ru";
import {
  banners,
  bonuses,
  clinic,
  clinics,
  courseInstallment,
  courseSelectedReducer,
  delivery,
  doctor,
  doctorPays,
  doctorTasks,
  fileSpinner,
  fileUploaded,
  fileUploadError,
  instructions,
  invoice,
  media,
  media_s3,
  meditCases,
  meditPatients,
  patient,
  patientData,
  patients,
  patientSaving,
  selectCourseReducer,
  services,
  shipments,
  smiles,
  spinner,
  threeShapeCases,
  token,
  tour,
  userActionNotification,
} from "./dashboard";
import { event } from "./events";
import {
  bannersIsLoading,
  comments,
  errorMessage,
  notifications,
  notificationWasShown,
  user,
  userIsLoading,
} from "./login";
import { offer } from "./offer";

const appReducer = combineReducers({
  devTools,
  bannersIsLoading,
  userIsLoading,
  notificationWasShown,
  user,
  token,
  doctor,
  clinic,
  clinics,
  patient,
  patients,
  meditPatients,
  patientSaving,
  instructions,
  media,
  media_s3,
  comments,
  errorMessage,
  intl: intlReducer,
  form: formReducer,
  userActionNotification,
  invoice,
  delivery,
  offer,
  patientData,
  courseInstallment,
  selectCourseReducer,
  courseSelectedReducer,
  services,
  doctorTasks,
  spinner,
  doctorPays,
  threeShapeCases,
  meditCases,
  fileUploaded,
  bonuses,
  smiles,
  banners,
  shipments,
  event,
  fileSpinner,
  fileUploadError,
  tour,
  notifications,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_STATE") {
    state = {
      userIsLoading: false,
      intl: isEnglishLocale()
        ? { locale: "en", messages: englishMessages }
        : { locale: "ru", messages: russianMessages },
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
