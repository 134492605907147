import { saveDraftPatientNotification } from '../components/notifications/save_patient_draft';
import { submitPatientNotification } from "../components/notifications/submit_patient_notification";
import { API_POST_PATIENT, API_PUT_PATIENT, API_PUT_UNCOMPLETE_PATIENT, API_SUBMIT_PATIENT } from '../config';
import type { TPatient, TUserActionNotification } from '../reducers/dashboard';
import type { AppDispatch } from '../store';
import { addUserActionNotification } from "./user_notification";

export function patientIsSavingSuccess(bool: boolean) {
  return { type: "PATIENT_SAVE_SUCCESSFULL", patientIsSaving: bool } as const;
}

export function createAndSubmitPatient(instructions, media, media_s3) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.patient.create.ok",
    level: 'success',
    position: 'tc',
    autoDismiss: 10,
    children: submitPatientNotification()
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.create.error",
    level: 'error',
    position: 'tl',
    autoDismiss: 4,
  };

  instructions["media_info"] = media
  instructions["s3_media"] = media_s3

  return (dispatch: AppDispatch) => {
    fetch(API_POST_PATIENT, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error trying to create patient');
      }
      return response;
    })
    .then(response => response.json())
    .then(response =>
      fetch(API_SUBMIT_PATIENT(response.patient_id), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error trying to submit patient');
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
        dispatch(patientIsSavingSuccess(true));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        dispatch({ type: 'ADD_PATIENT_SUCCESSFULL', json });
        dispatch(patientIsSavingSuccess(false));
    })
    .catch(() => {
      dispatch(patientIsSavingSuccess(false));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR))
    });
  }
}


export function createPatient(instructions, media, media_s3) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.patient.create.draft.ok",
    level: 'success',
    position: 'tc',
    autoDismiss: 0,
    children: saveDraftPatientNotification()
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.create.error",
    level: 'error',
    position: 'tl',
    autoDismiss: 4,
  };

  instructions["media_info"] = media
  instructions["s3_media"] = media_s3

  return (dispatch: AppDispatch) => {
    fetch(API_POST_PATIENT, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to create patient');
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
        dispatch(patientIsSavingSuccess(true));
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
        dispatch({ type: 'ADD_PATIENT_SUCCESSFULL', json });
        dispatch(patientIsSavingSuccess(false));
    })
    .catch(() => {
      dispatch(patientIsSavingSuccess(false));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    });
  }
}


export function updatePatient(patient_id, {instructions, media, media_s3}) {
  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: 4,
  };

  instructions["media_info"] = media
  instructions["s3_media"] = media_s3

  return (dispatch: AppDispatch) => {
    fetch(API_PUT_UNCOMPLETE_PATIENT(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to update patient');
      }
      return response;
    })
    .then(() => {
      window.location = '/pages/patients';
    })
    .catch(() => {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    })
  }
}


export function updateAndSubmitPatient(patient_id: TPatient["patient_id"], { instructions, media, media_s3 }) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.patient.create.ok",
    level: 'success',
    position: 'tc',
    autoDismiss: 10,
    children: submitPatientNotification()
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: 4,
  };

  instructions["media_info"] = media
  instructions["s3_media"] = media_s3

  return (dispatch: AppDispatch) => {
    fetch(API_PUT_PATIENT(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to update patient');
      }
      return response;
    })
    .then((response) => response.json())
    .then(response =>
      fetch(API_SUBMIT_PATIENT(response.patient_id), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to submit patient');
      }
      return response;
    })
    .then(() => {
      dispatch(patientIsSavingSuccess(true));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      dispatch(patientIsSavingSuccess(false));
    })
    .catch(() => {
      dispatch(patientIsSavingSuccess(false));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    });
  }
}


export function updateMedia(patient_id, { media, media_s3, links = null }) {
  const instructions = {}
  instructions["media_info"] = media
  instructions["s3_media"] = media_s3
  instructions["links"] = links

  return (dispatch: AppDispatch) => {
    fetch(API_PUT_PATIENT(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred tryint to update media');
      }
      return response;
    })
    .then((response) => response.json())
    .then(
      json => {
        dispatch({type: 'UPDATE_PATIENT_SUCCESSFULL', json});
      }
    ).catch(() => {});
  }
}
