import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Modal from "react-modal";
import { connect } from "react-redux";
import translit from "translitit-cyrillic-russian-to-latin";

import { getComments } from "../../../actions/comments";
import { eraseStateProp } from "../../../actions/dashboard";
import { COMMENT_TYPE_OPTIONS, DN_MAP_PALMER, PLAN_LANG_OPTION_MAP } from "../../../common/constants";
import { Status } from "../../../common/courses";
import remoteLog from "../../../common/logging";
import { deployedUSA } from "../../../common/utils";
import { PLAN_LINK_V2, PLAN_LINK_V2_RU } from "../../../config";
import { FormatDate } from "../../common/FormatDate";
import Loader from "../../common/loadingInProgress";
import { formatPersonName } from "../../common/PersonName";
import PatientAskQuestion from "../patient_ask_question";
import PatientSendComment from "../patient_send_comment";


const mapStateToProps = (state) => {
  return {
    user: state.user,
    intl: state.intl,
    comments: state.comments,
    patient: state.patient,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (patient_id) => dispatch(getComments(patient_id)),
    eraseStateProp: (props) => dispatch(eraseStateProp(props))
  };
};


class TranslitFromCyrillic extends Component {
  componentDidCatch(e) {
    remoteLog(e, "patient_comments");
  }

  render() {
    let locale = this.props.locale;
    let id = this.props.id;
    if (locale !== "ru") {
      return <span>{translit(id)}</span>;
    } else {
      return <span>{id}</span>;
    }
  }
}


class PatientCommentsPredict extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      // question: [],
      canSubmit: true,
      showLoader: false,
      comment: null,
      question: null,
      showAll: false,
    };
  }

  UNSAFE_componentWillMount() {
    let patient_id = this.props.match.params.patient_id;
    this.props.getData(patient_id);
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.comments).length === 0) {
      this.setState({ comments: [] });
    } else {
      this.setState({ comments: nextProps.comments });
      this.setState({ canSubmit: true });
      this.setState({ showLoader: false });
    }
  }

  componentWillUnmount() {
    this.props.eraseStateProp("comments");
  }

  open3dPlan(data) {
    const { user } = this.props;
    let langLocale = this.props.intl.locale;
    if (deployedUSA() === true) {
      langLocale =
        PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] ||
        PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(
        PLAN_LINK_V2(
          this.props.patient.patient_id,
          this.props.token,
          langLocale
        )
      );
    } else {
      window.open(
        PLAN_LINK_V2_RU(
          this.props.patient.patient_id,
          this.props.token,
          langLocale
        )
      );
    }
  }

  renderCommentHint(comment) {
    if (
      comment &&
      comment.type &&
      Object.keys(COMMENT_TYPE_OPTIONS).includes(String(comment.type))
    ) {
      const { type } = comment;
      return (
        <span
          style={{
            color: COMMENT_TYPE_OPTIONS[type].color,
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          <FormattedMessage id={COMMENT_TYPE_OPTIONS[type].translation} />
        </span>
      );
    }
    return null;
  }


  render() {

    const hideCommentInput = this.props.patient && this.props.patient.status == Status._3D_PLAN_APPROVAL;
    const comment = this.state.comments[0];

    if (this.props.comments) {
      return (
        <div className="portlet light bordered" id="comment-block">
          <div className="portlet-title" id="comment-title">
            <div className="caption">
              <i className="icon-speech font-green" />
              <span
                className="caption-subject font-green bold uppercase"
                id="comment-block-label"
              >
                <FormattedMessage id="pat.comment.title" />
              </span>
            </div>
          </div>
          <div>
            {hideCommentInput ? (
              <div className="alert alert-warning" id="comment-form-mobile">
                <span
                  style={{ color: "black" }}
                  id="comment-form-mobile-warning"
                >
                  <FormattedMessage id="comment.message.on.plan.approve" />
                </span>
                .&nbsp;
                {this.props.token &&
                this.props.patient.media["3d-plan"].response ? (
                  <span
                    id="comment-form-mobile-plan-button"
                    onClick={(e) => this.open3dPlan(e)}
                    style={{
                      color: "#008996",
                      fontWeight: "600",
                      fontWeight: "15px",
                    }}
                  >
                    <u>
                      <FormattedMessage id="pat.plan.button" />
                    </u>
                  </span>
                ) : null}
              </div>
            ) : null }
            <div className="form-actions">
              {!hideCommentInput ? (
                <PatientSendComment />
              ) : null}
              {this.props.patient.status != Status._3D_PLAN_APPROVAL ? <PatientAskQuestion/> : false}
              {this.state.showLoader && <Loader />}
            </div>
          </div>

          {comment && comment !== undefined && !this.state.showAll ? (
            <div className="portlet-body">
              <div className="timeline">
                <div className="timeline-item" id="comment-body">
                  <div className="timeline-badge">
                    <div className="timeline-icon">
                      <i className="icon-user font-purple" />
                    </div>
                  </div>
                  <div className="timeline-body">
                    <div className="timeline-body-arrow"></div>
                    <div className="timeline-body-head">
                      <div className="timeline-body-head-caption">
                        <span
                          data-matomo-mask
                          data-hj-suppress
                          className="timeline-body-title font-blue-madison"
                          id="comment-block-authon"
                        >
                          {comment.commenter.external === true ? (
                            comment.commenter.full_name
                          ) : (
                            <TranslitFromCyrillic
                              id={formatPersonName({
                                person: {
                                  ...comment.commenter,
                                  account_id: comment.account_id,
                                },
                                user: this.props.user,
                              })}
                              locale={this.props.intl.locale}
                            />
                          )}
                        </span>
                        &nbsp;
                        <FormatDate
                          className="timeline-body-time font-grey-cascade"
                          value={comment.timestamp}
                          date
                        />
                      </div>
                      <div className="timeline-body-head-actions">
                        {this.renderCommentHint(comment)}
                      </div>
                    </div>
                    <div className="timeline-body-content">
                      <span
                        data-matomo-mask
                        data-hj-suppress
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                        className="font-grey-cascade"
                        id="comment-block-content"
                      >
                        {comment.comment}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.showAll ? (
            <div className="portlet-body">
              <div className="timeline">
                {this.state.comments.map((c, i) => (
                  <div key={i} className="timeline-item" id="comment-body">
                    <div className="timeline-badge">
                      <div className="timeline-icon">
                        {this.props.user.account_id == c.account_id ? (
                          <i className="icon-user font-red-haze" />
                        ) : (
                          <i className="icon-user font-purple" />
                        )}
                      </div>
                    </div>
                    <div className="timeline-body">
                      <div className="timeline-body-arrow"></div>
                      <div className="timeline-body-head">
                        <div className="timeline-body-head-caption">
                          <span
                            data-matomo-mask
                            data-hj-suppress
                            className="timeline-body-title font-blue-madison"
                            id="comment-block-authon"
                          >
                            {c.account_id == this.props.user.account_id ? (
                              <TranslitFromCyrillic
                                id={formatPersonName({
                                  person: {
                                    ...c.commenter,
                                    account_id: c.account_id,
                                  },
                                  user: this.props.user,
                                })}
                                locale={this.props.intl.locale}
                              />
                            ) : c.commenter.external === true ? (
                              c.commenter.full_name
                            ) : (
                              <TranslitFromCyrillic
                                id={formatPersonName({
                                  person: {
                                    ...c.commenter,
                                    account_id: c.account_id,
                                  },
                                  user: this.props.user,
                                })}
                                locale={this.props.intl.locale}
                              />
                            )}
                          </span>
                          &nbsp;
                          <FormatDate
                            className="timeline-body-time font-grey-cascade"
                            value={c.timestamp}
                            date
                          />
                        </div>
                        <div className="timeline-body-head-actions">
                          {this.renderCommentHint(c)}
                        </div>
                      </div>
                      <div className="timeline-body-content">
                        <span
                          data-matomo-mask
                          data-hj-suppress
                          style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                          className="font-grey-cascade"
                          id="comment-block-content"
                        >
                          {c.comment}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {this.state.comments.length > 1 ? (
            <div style={{ marginLeft: "12px" }}>
              <a onClick={() => this.setState({ showAll: !this.state.showAll })} >
                {!this.state.showAll
                  ? <FormattedMessage id="pat.comments.show" />
                  : <FormattedMessage id="pat.comments.hide" />
                }
              </a>
            </div>
          ) : null}
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientCommentsPredict);

