import { CheckCircledIcon, CrossCircledIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { isMatching, match, P } from "ts-pattern";

import type { TYooKassaPaymentStatus } from "../../api";
import Loader from "./loadingInProgress";

const isQueryError = isMatching({ status: P.number });

export function PaymentStatusMessage({
  query,
}: {
  query: {
    isUninitialized: boolean;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    data?: { status: TYooKassaPaymentStatus };
    error?: unknown;
  };
}) {
  let content;

  if (query.isUninitialized || query.isLoading) {
    content = <Loader />;
  }

  if (query.isError) {
    if (isQueryError(query.error) && query.error.status == 404) {
      content = (
        <>
          <CrossCircledIcon className="tw-h-5 tw-w-5 tw-text-red-600" />
          <span className="tw-font-semibold">
            <FormattedMessage id="pat.payments.course.status.not_found" />
          </span>
        </>
      );
    } else {
      content = (
        <>
          <CrossCircledIcon className="tw-h-5 tw-w-5 tw-text-red-600" />
          <span className="tw-font-semibold">
            <FormattedMessage id="pat.payments.course.status.error" />
          </span>
        </>
      );
    }
  }

  if (query.isSuccess && query.data) {
    content = match(query.data.status)
      .with("succeeded", () => (
        <>
          <CheckCircledIcon className="tw-h-5 tw-w-5 tw-text-green-600" />
          <span className="tw-font-semibold">
            <FormattedMessage id="pat.payments.course.paid" />
          </span>
        </>
      ))
      .with("pending", () => (
        <>
          <InfoCircledIcon className="tw-h-5 tw-w-5 tw-text-yellow-600" />
          <span className="tw-font-semibold">
            <FormattedMessage id="pat.payments.course.status.pending" />
          </span>
        </>
      ))
      .with("canceled", () => (
        <>
          <CrossCircledIcon className="tw-h-5 tw-w-5 tw-text-red-600" />
          <span className="tw-font-semibold">
            <FormattedMessage id="pat.payments.course.status.canceled" />
          </span>
        </>
      ))
      .exhaustive();
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-2 max-desktop:tw-justify-center">{content}</div>
  );
}
