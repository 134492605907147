const messages = {
  // General
  "not.selected"                      : "Не выбрано",
  None                                : "Не указано",
  None_USA                            : "Не указано",
  None_V2                             : "Не указано",
  NA                                  : "-",
  "table.search"                      : "Поиск",
  "table.status"                      : "Выберите статус",
  "table.clear"                       : "Очистить",
  "button.all"                        : "Все",
  "table.from"                        : "С",
  "table.to"                          : "До",
  "button.save_changes"               : "Сохранить изменения",
  "general.payment.show"              : "Показать платежи",
  "general.payment.close"             : "Скрыть платежи",
  "general.retry"                     : "Повторить",
  "bool.true"                         : "Да",
  "bool.false"                        : "Нет",
  "word.and"                          : "и",
  "select.option"                     : "Выбрать",
  "date"                              : "Дата",
  "millimetre"                        : "мм",
  "requiredField"                     : "обязательные поля",
  "doctor.fullname"                   : "ФИО",
  "doctor.profile.info"               : "Информация о профиле",
  "not.found"                         : "Информация не найдена",
  "click.to.copy"                     : "Нажмите для копирования",
  "next"                              : "Следующая",
  "previous"                          : "Предыдущая",
  "cancel"                            : "Выйти без сохранения",
  "fifty"                             : "50",
  "hundred"                           : "100",
  "two.hundred"                       : "200",
  "here"                              : "здесь",
  "page"                              : " ",
  "loading"                           : "Загрузка...",
  "search"                            : "Поиск",
  "search.clear"                      : "Очистить поиск",
  "error_boundary.title"              : "Ошибка",
  "error_boundary.message"            : "Что-то пошло не так",
  "error_boundary.message_2"          : "Попробуйте перезагрузить страницу или повторить попытку позже",
  "scroll_to_top"                     : "Пролистать наверх",
  "profile_menu"                      : "Меню профиля",
  "alert.close"                       : "Закрыть",
  "telegram_id.required"              : "Это поле обязательно к заполнению при подписке на Telegram уведомления",

  E_CONNECTION_FAILED: "Ошибка соединения с сервисом",

  // login page
  "login.header": "Войдите в кабинет",
  "login.login": "Логин",
  "login.password": "Пароль",
  "login.show_password": "Показать пароль",
  "login.enter": "Войти",
  "login.forgot": "Забыли пароль ?",
  "login.forgot_cta": "Восстановить пароль",
  E_INVALID_LOGIN: "Неверные данные авторизации",
  // password reminder
  "reminder.forgot": "Восстановление пароля",
  "reminder.email":
    "Введите логин или email и получите письмо со ссылкой для восстановления пароля",
  "reminder.placeholder": "Email",
  "reminder.placeholder.login": "Логин или Email",
  "reminder.back": "Назад",
  "reminder.submit": "Восстановить",
  "reminder.mail_send": "Ссылка для восстановления отправлена на Вашу почту. ",
  "reminder.link": "Перейти на страницу логина.",

  "restore_password.success": "Пароль установлен. ",
  "restore_password.success_link": "Перейти на страницу логина.",
  "restore_password.fail":
    "Ссылка для восстановления недействительна. Пожалуйста начните процедуру восстановления пароля заново. ",
  "restore_password.fail_link": "Восстановить пароль.",
  "reminder.payment"                  : " ",
  "reminder.payment.card"             : " ",
  "reminder.payment.invoice"          : " ",
  "reminder.my.payments"              : " ",

  //tooltips
  "tooltip_shipment"                  : "Здесь можно отслеживать Ваши доставки",
  "tooltip_payments"                  : "Здесь все Ваши платежи",
  "tooltip_payment"                   : "",
  "tooltip_notifications"             : "Здесь все Ваши уведомления",
  "tooltip_3d_plan_approval_date"     : "Капы будут доставлены Вам в течении 15 дней от даты утверждения 3D плана",
  "tooltip_precise_first_comment"     : "Первый комментарий 3D Precise - {labelTitle} ",
  "tooltip_precise_last_comment"      : "Последний комментарий 3D Precise - {labelTitle}",
  "required.fields.tooltip"           : "Все поля, отмеченные звездочкой, являются обязательными.",
  "course.type.tooltip"               : "Ознакомиться с таблицей “Выбор курса” можно в разделе ",
  "course.type.tooltip.sec"           : "(выбор курса зависит от длительности лечения и необходимых движений).",
  "tooltip.chart"                     : "инструкции",
  "upload.photos.tooltip"             : "Высококачественные изображения имеют решающее значение для точности плана лечения",
  "upload.photos.manual.tooltip"      : " ",
  "cbct.upload.tooltip"               : " ",
  "elastics.tooltip"                  : "Не являются обязательными при работе с системой 3D Smile для большинства движений. Подробнее в разделе ",
  "tooltip.correction.btn.title"      : " ",
  "tooltip.correction.btn.payment.status": " ",
  "tooltip.correction.btn.warranty.date" : " ",
  "tooltip.correction.btn.error"      : " ",
  "tooltip.corrcetion.btn.support"    : " ",
  "tooltip.correction.btn.no.course"  : " ",
  "tooltip.retainers"                 : " ",
  "tooltip.support"                   : " ",
  "tooltip.click.approve"             : "Перейти к утверждению 3D Плана",

  // Left menu
  "left.menu.header": "Личный кабинет",
  "left.menu.patients": "Пациенты",
  "left.menu.add_patient": "Добавить пациента",
  "left.menu.shipments" : "Мои доставки",
  "left.menu.my_payments": "Мои платежи",
  "left.menu.best_case": "Лучшие случаи",
  "left.menu.library": "Библиотека клинических случаев",
  // Top menu
  "top.menu.profile": "Мой профиль",
  "top.menu.my_practices": "Мои клиники",
  "top.menu.add_practices": "Добавить клинику",
  "top.menu.logout": "Выйти",
  // Patient table header
  "pat_table.block_head": "Пациенты",
  "pat_table_tasks.block_head": "Текущие задачи",
  "pat_table.name": "ФИО пациента",
  "pat_table.delivered": "Этапов доставлено",
  "pat_table.received": "Оплачено",
  "pat_table.payments": "Оплаты",
  "pat_table.tot_payment": "Сумма оплаты",
  "pat_table.status": "Статус пациента",
  "pat_table.of": "из",
  "pat_table.total": "Итого",
  "pat_table.services": "Услуги",
  "pat_table_tasks.block_payments": "Платежи",
  "pat_table.block_banners" : "Новости",
  // Clinics table header
  "clin_table.header": "Мои клиники",
  "clin_table.name": "Название",
  "clin_table.address": "Адрес",
  "clin_table.phone": "Номер телефона",
  "clin_table.email": "Email",
  "clin_table.contacts": "Контакты",
  // Clinic view/edit
  "clin_view.header": "ИНФОРМАЦИЯ О КЛИНИКЕ",
  "clin_view.edit.header": "РЕДАКТИРОВАТЬ КЛИНИКУ",
  "clin_view.name": "Клиника",
  "clin_view.address": "Адрес клиники",
  "clin_view.zip": "Почтовый индекс",
  "clin_view.phone": "Номер телефона клиники",
  "clin_view.email": "Email клиники",
  "clin_view.contacts": "Контактные данные",
  "clin_view.add.header": "Добавить клинику",
  "clin_view.name.placeholder": "Название",
  "clin_view.address.placeholder": "Адрес клиники",
  "clin_view.zip.placeholder": "Почтовый индекс",
  "clin_view.phone.placeholder": "Номер телефона клиники",
  "clin_view.logistic_phone.placeholder":
    "Номер телефона для службы доставки (не более 15 символов)",
  "clin_view.logistic_contact.placeholder":
    "Контактное лицо для связи со службой доставки (не более 35 символов)",
  "clin_view.logistic_phone": "Номер телефона для службы доставки",
  "clin_view.logistic_contact": "Контактное лицо для связи со службой доставки",
  "clin_view.email.placeholder": "Email клиники",
  "clin_view.contacts.placeholder":
    "С кем связываться по счетам и договорам...",
  "clin_view.add.button": "Добавить клинику",
  "clin_view.confirm": "Вы действительно хотите отвязать клинику?",
  "clin_view.schedule.schedule": "График работы",
  "clin_view.schedule.monday": "Понедельник",
  "clin_view.schedule.tuesday": "Вторник",
  "clin_view.schedule.wednesday": "Среда",
  "clin_view.schedule.thursday": "Четверг",
  "clin_view.schedule.friday": "Пятница",
  "clin_view.schedule.saturday": "Суббота",
  "clin_view.schedule.sunday": "Воскресенье",
  "clin_view.schedule.from": "с",
  "clin_view.schedule.to": "до",
  "clin_view.address.line.1": "Адрес",
  "clin_view.address.line.2": "Дом",
  "clin_view.address.line.3": "Город",
  "clin_view.address.not.more.then.35": " ",
  "clin_view.address.line.1.placeholder": "Улица (не более 35 символов)",
  "clin_view.address.line.2.placeholder":
    "Дом / строение / корпус / … (не больше 35 символов)",
  "clin_view.address.line.3.placeholder":
    "Город / страна / … (не более 35 символов)",
  "clin_view.detach": "Отвязать клинику",
  "clin_view.delivery.contacts": "Контакты по вопросам доставки",
  "clin_view.administrative.contacts": "Контакты по административным вопросам",

  "clin_view.error.35.symbols": "Поле должно содержать не более 35 символов",
  "clin_view.error.phone": "Неверный номер телефона",
  "clin_view.error.email": "Неверный формат адреса электронной почты",
  "clin_view.error.index": "Неверный формат индекса",
  "clin_view.error.logistic.phone":
    "Номер телефона должен состоять только из цифр и содержать не более 15 символов",
  "clin_view.error.logistic.contact":
    "Поле должно содержать не более 35 символов",
  "clinics_not_found": "Клиники не найдены",

  // Right blocks patient page
  "pat.block.not_found": "Пациент не найден",
  "pat.block.right.header": "Пациент",
  "pat.block.right.id": "ID",
  "pat.block.right.name": "ФИО",
  "pat.block.right.instructions": "Дата создания заявки",
  "pat.block.right.plan_approval": "Дата утверждения 3D плана",
  "pat.block.right.aligners.eta": "Дата доставки ",
  "pat.block.right.additional.eta" : "Дата доставки дубликатов ",
  "pat.block.right.retainers.eta" : "Дата доставки ретейнеров ",
  "pat.block.right.delivered": "Этапов доставлено",
  "pat.block.right.correction": "Коррекция",
  "pat.block.right.additional_course": "Дополнительный комплект",
  "pat.block.right.additional": "Дополнительные услуги",
  "pat.block.right.general.treatment": "Основной курс",
  "pat.block.right.start_date": "Дата начала работ",
  "pat.block.right.plan_date": "3D-план ожидается",
  "pat.block.right.caps_date": "Отправка кап ожидается",
  "pat.block.right.warranty_end_date": "Дата окончания гарантии",
  "pat.block.status.header": "СТАТУС ПАЦИЕНТА",
  "pat.block.deliveries.header": "ДОСТАВКА",
  "pat.block.impression.delivery.header": "ОТПРАВКА СЛЕПКОВ",
  "pat.block.impression.delivery.all": "смотреть все отправления",
  "pat.block.deliveries.waybills": "Трекинг номер",
  "pat.shipments.tracking_number": "Трекинг номер",
  "pat.shipments.eta": " ",
  "pat.shipments.header": "Мои доставки",
  "pat.shipments.description": "Здесь Вы можете отслеживать доставку элайнеров",
  "pat.shipments.selected.date": "Просмотреть с выбранной даты",
  "notify.shipments.error": "Ошибка при попытке получения отправлений",
  "pat.deliveries.button.noWaybills": "Накладные не найдены",
  "pat.block.status.status": "Статус",
  "pat.block.payment.header": "СТАТУС ОПЛАТЫ",
  "pat.block.payment.status": "Статус оплаты",
  "pat.block.payment.received": "Оплачено",
  "pat.block.payment.total": "Сумма оплаты",
  "pat.block.payment.currency": "руб.",
  "pat.block.payment.currency.curr": "руб.",
  "pat.block.payment.currency.usa": "$",
  "pat.block.payment.payments": "Курс",
  "pat.block.payment.extras": "Дополнительные услуги",
  "pat.block.payment.pay.all": "Оплатить оставшуюся сумму целиком",
  "pat.block.payment.pay.next": "Оплатить сумму",
  "pat.deliveries.button.callACourier": "Вызвать курьера",
  "pat.deliveries.button.callACourierByPhone": "Вызвать курьера по телефону",
  "pat.deliveries.button.callCourier.confirmation":
    "Подтвердить вызов курьера по телефону",
  "pat.deliveries.button.getWaybill": "Получить накладную",
  "pat.deliveries.button.getNewWaybill": "Получить новую накладную",
  "pat.deliveries.button.cancel": "Отменить",
  "pat.block.deliveries.estimated.pickup_date": "Дата приезда курьера",
  "pat.block.deliveries.current.pickups": "Текущее отправление",
  "pat.block.deliveries.see.waybill": "(распечатать накладную)",
  "pat.block.deliveries.aligner.delivery": "Трекинг номер (доставка кап)",
  "pat.block.deliveries.aligner.all": "все доставки",
  "pat.block.deliveries.type": "Тип",
  "pat.block.deliveries.type.laboratory": "Отправка слепков",
  "pat.block.deliveries.type.clinic": "Доставка кап",
  "pat.block.deliveries.shipment.date": "Дата отправления",
  "pat.block.deliveries.shipments.aligners": "Доставка кап",
  "pat.block.deliveries.deliveries.impressions": "Отправка слепков",
  "pat.block.deliveries.ups.site.track": "Проверить статус отправления",
  "pat.deliveries.contact.to.manager":
    "Отправление через ЛК невозможно. Неверно заполнены данные клиники. Обратитесь к менеджеру.",
  "pat.deliveries.error.E_INVALID_FROM":
    "Неверно заполнена информация о клинике",
  "pat.deliveries.error.E_UNHANDLED_ERROR": "Сервис временно недоступен",
  "pat.deliveries.error.E_SERVICE_UNAVAILABLE": "Сервис временно недоступен",
  "pat.payments.amount.to.pay": "Сумма к оплате",
  "pat.payments.buttons.pay": "Перейти к оплате",
  "pat.payments.buttons.submit": "Подтвердить",
  "pat.payments.online.payment": "Онлайн оплата",
  "pat.payments.you.will.be.redirected":
    "Сейчас вы будете перенаправлены на форму оплаты",
  "pat.payments.course.paid": "Оплата завершена успешно",
  "pat.payments.course.paid.error": "При проведении платежа произошла ошибка",
  "pat.payments.course.pay.course": "Оплата",
  "pat.payments.course.status.error": "Что-то пошло не так во время загрузки статуса платежа",
  "pat.payments.course.status.canceled": "Платеж был отменен",
  "pat.payments.course.status.pending": "Платеж cоздан и ожидает оплаты",
  "pat.payments.course.status.not_found": "Платеж не найден",
  "pat.payments.payment_status.goto": "Перейти к пациенту",

  //Right blocks next step status
  FIRST_3D_PLAN: "Ожидается 3D план",
  NEXT_3D_PLAN: "Ожидается 3D план",
  FIRST_SHIPMENT: "Отправка ожидается",
  NEXT_SHIPMENT: "Следующая отправка",
  NEXT_SHIPMENT_PAYMENT_EXPECTED: "Ожидание оплаты",
  // Patient view-page-only blocks
  "pat.comment.header": "ДОБАВЛЕНИЕ КОММЕНТАРИЯ",
  "pat.comment.title": "Комментарии ",
  "pat.comment.placeholder": "Комментарий...",
  "pat.comment.button": "Добавить комментарий",
  "pat.comments.hide" : " ",
  "pat.comments.show" : " ",
  "pat.plan.header": "3D ПЛАН",
  "pat.plan.button": "Cмотреть 3D план",
  "pat.plan.link": "Ссылка для пациента",
  "pat.plan.copy_link": "Скопировать ссылку",
  "pat.view.header": "КАРТА ПАЦИЕНТА",
  "pat.status.corr_stage": "Номер этапа:",
  "pat.status.button.correction": "Заказать коррекцию",
  "pat.status.button.additional_course": "Заказать дополнительный комплект",
  "pat.status.button.correction.new": "Заказать коррекцию (Новая форма)",
  "pat.status.button.retainers": "Заказать ретенционные капы",
  "pat.status.button.aligners.additional": "Заказать дубликаты кап",
  "pat.status.button.end": "Завершить лечение",
  "pat.status.button.end.confirm": "Завершить",
  "pat.status.button.end.description": "Вы уверены, что хотите завершить лечение пациента",
  "pat.status.button.confirm": "Подтверждение",
  "pat.question.button": "Задать вопрос клиническому эксперту",
  "pat.question.modal.title": "Вопрос клиническому эксперту",
  "pat.question.modal.restricted.sym":
    'Нельзя использовать следующие символы "<" ">" ',
  "pat.retainers.page.header": "Ретенционные капы",
  "RETAINERS_COMMENT" : " ",
  "pat.additional.aligners.page.header": "Дубликаты кап",
  "pat.comment.rework.header"         : "Пожалуйста, не забудьте оставить комментарий",
  "pat.payments.dear.doctor"          : "Уважаемый доктор, ",
  "pat.payments.thanks"               : "Спасибо ",
  "pat.payments.payment"              : "за оплату!",
  "pat.payments.invoice"              : " ",
  "notify.ok"                         : "Ok",
  "notify.done"                       : "Готово",
  "pat.additional.aligners.add.stage" : "Добавить этап",
  "pat.additional.aligners.required.fields": "Заполните обязательные поля",
  "pat.additional.aligners.required.fields.remove.stage": "Заполните обязательные поля или удалите дополнительный этап",


  // Status names
  S_UNFILLED_CASE: "Недозаполненная заявка",
  S_INSTRUCTIONS_PROCESSING: "Заявка обрабатывается",
  S_RETAKE_IMPRESSIONS: "Ожидание информации",
  S_WAITING_FOR_A_PAYMENT: "Ожидание оплаты",
  S_3D_SCANNING: "Сканирование",
  S_3D_PLAN_MODELING: "Моделирование 3D плана",
  S_3D_PLAN_APPROVAL: "Утвердите 3D план",
  S_3D_PLAN_MODELS_BUILDING: "Лечение",
  S_PRODUCTION_PRINTING: "Лечение",
  S_PRODUCTION_MOLDING: "Лечение",
  S_PRODUCTION_MARKING: "Лечение",
  S_PRODUCTION_ALLOCATING: "Лечение",
  S_PRODUCTION_FABRICATING: "Лечение",
  S_PRODUCTION_QUALITY_CONTROL: "Лечение",
  S_FINAL_STAGE_READY: "Капы финального этапа готовы",
  S_TREATMENT_FINISHED: "Лечение завершено",
  S_CANCEL_TREATMENT: "Отказался от лечения",
  S_RETAINERS_ORDERED: "Заявка обрабатывается (ретенционные капы)",
  S_RETAINERS_PRINTING: "Ретенционные капы - изготовление",
  S_RETAINERS_FABRICATING: "Ретенционные капы - изготовление",
  S_RETAINERS_READY: "Ретенционные капы готовы",
  S_RETAINERS_3D_SCANNING: "Ретенционные капы - изготовление",

  // Create patient page
  BLOCKHEAD_DOCTOR: "Информация о враче",
  HEADER_DOCTOR: "Врач",
  HEADER_CLINIC: "Клиника",
  CLINIC_SHIP_COMMENTS: "Комментарии по логистике ",
  CLINIC_SHIP_COMMENTS_PLACEHOLDER: "Комментарии",
  BLOCKHEAD_PAYMENT: "Оплата",
  HEADER_PAYMENT_METHOD: "Способ оплаты",
  HEADER_PAYER: "Плательщик",
  PAYER_CLINIC: "Клиника",
  PAYER_DOCTOR: "Врач",
  PAYER_PATIENT: "Пациент",
  PM_CASH: "Наличный расчет",
  PM_BANK: "Безналичный расчет",
  PM_INVOICE: "Безналичный расчет",
  PM_CARD: "Оплата картой в Личном Кабинете",
  "PM_CARD.usa": "Оплата банковской картой",
  HEADER_PAYMENT_OPTION: "Варианты оплаты",
  PO_ADVANCE: "100% предоплата",
  PO_SPLIT_2: "Рассрочка в два этапа",
  PO_SPLIT_3: "Рассрочка в три этапа",
  PO_CREDIT_SB: "Покупай со Сбером в кредит",
  PO_CREDIT_SB_COND: "(условия кредитования)",
  PS_3D_PLAN: "Оплачен только 3D план",
  PS_HALF_PRICE: "Оплачена половина этапов",
  PS_FULL_PRICE: "Оплачены все этапы",
  BLOCKHEAD_PAT_INFO: "Информация о пациенте",
  PAT_INFO_LAST_NAME: "Фамилия ",
  PAT_INFO_LAST_NAME_PLACEHOLDER: "Фамилия пациента",
  PAT_INFO_NAME_TOOLTIP: "ФИО пациента не подлежит изменению после отправки заявки в работу",
  PAT_INFO_FIRST_NAME: "Имя ",
  PAT_INFO_FIRST_NAME_PLACEHOLDER: "Имя пациента",
  PAT_INFO_MIDDLE_NAME: "Отчество ",
  PAT_INFO_MIDDLE_NAME_PLACEHOLDER: "Отчество пациента",
  PAT_EMAIL: "Email плательщика",
  PAT_INFO_EMAIL_PLACEHOLDER:
    "Email (на указанный Email будет выслана ссылка для оплаты)",
  HEADER_SEX: "Пол",
  SEX_F: "Женский",
  SEX_M: "Мужской",
  HEADER_BDATE: "Дата рождения",
  HEADER_CONDITION: "Диагноз / Клиническое состояние",
  CONDITION_PLACEHOLDER: "Основная жалоба / Диагноз",
  BLOCKHEAD_INSTRUCTIONS: "Рецепт",
  HEADER_INSTRUCTIONS: "Курс лечения",
  ATTACHMENT_INSTRUCTIONS: "Выберите одну из опций",
  ADDITIONAL_INSTRUCTIONS: "Дополнительные услуги",
  C_MATCHING: "Совмещение КТ и скана зубных рядов",
  C_FULL: "Полный",
  C_FULL_V2: "Полный",
  C_FULL_COMMENT_V2:
    " (включает: виртуальный 3D план, до 30 этапов в основном плане, 2 коррекции)",
  C_FULL_COMMENT:
    " (3D план движения коронок, 3 коррекции, неограниченное количество этапов)",
  C_FULL_USA: "PREDICT FULL",
  C_FULL_COMMENT_USA: "Unlimited number of aligners, 3 corrections",
  C_TEEN: "Подростки",
  C_TEEN_V2: "Подростки",
  C_TEEN_COMMENT: " (включает: виртуальный 3D план, неограниченное количество этапов, 3 коррекции)",
  C_TEEN_COMMENT_V2: " (включает: виртуальный 3D план, неограниченное количество этапов, 3 коррекции)",
  C_SHORT: "Короткий",
  C_SHORT_V2: "Короткий",
  C_SHORT_COMMENT: " (3D план движения коронок, до 14 этапов на 2 челюсти)",
  C_SHORT_COMMENT_V2: " (включает: виртуальный 3D план, не более 14 этапов)",
  C_SHORT_USA: "PREDICT SHORT",
  C_SHORT_COMMENT_USA: "Limited to 14 aligners per arch",
  C_ONE_JAW: "Лечение одной челюсти",
  C_ONE_JAW_V2: "Курс на одну челюсть",
  C_ONE_JAW_COMMENT_V2:
    " (включает: виртуальный 3D план, неограниченное количество этапов, 2 коррекции)",
  C_ONE_JAW_COMMENT:
    " (3D план движения коронок, 2 коррекции, неограниченное количество этапов на 1 челюсть)",
  C_ONE_JAW_USA: "PREDICT SINGLE ARCH",
  C_ONE_JAW_COMMENT_USA: "Full case for Single Arch",
  C_SUPER_SHORT: "Супер-короткий",
  C_SUPER_SHORT_V2: "Супер-короткий курс до 8 этапов",
  C_SUPER_SHORT_COMMENT: " (3D план движения коронок, до 8 кап на 1 челюсть)",
  C_SUPER_SHORT_COMMENT_V2:
    " (включает: виртуальный 3D план, не более 8 кап на одну челюсть)",
  C_SUPER_SHORT_USA: "PREDICT SUPER SHORT",
  C_SUPER_SHORT_COMMENT_USA: "Limited to 8 aligners per arch",
  C_UNLIMITED: "Курс с моделированием движения корней",
  C_UNLIMITED_V2: "Курс с моделированием движения корней",
  C_UNLIMITED_USA: "DEEP CBCT",
  C_UNLIMITED_COMMENT:
    " (глубокий анализ КТ, 3D план движения корней и коронок, 3 коррекции, неограниченное количество этапов)",
  C_UNLIMITED_COMMENT_V2:
    " (включает: глубокий анализ КТ, виртуальный 3D план движения корней и коронок, неограниченное количество этапов, 3 коррекции)",
  C_UNLIMITED_COMMENT_USA:
    "Root and bone-based treatment planning, unlimited number of aligners, 3 corrections",
  C_3D_PLAN: "Только 3D план",
  C_3D_PLAN_USA: "Только 3D план",
  C_3D_PLAN_V2: "Только 3D план",
  C_CHILDREN: "Дети",
  C_CHILDREN_V2: "Курс ДЕТИ",
  C_CHILDREN_V3: "Дети Безлимитный",
  C_CHILDREN_COMMENT: " ",
  C_CHILDREN_COMMENT_V2: " (включает капы на 2 челюсти, 1 коррекция)",
  C_CHILDREN_COMMENT_V3: " (включает: виртуальный 3D план, неограниченное количество этапов в течение 18 месяцев)",
  C_CHILDREN_SHORT: "Дети Экспресс",
  C_CHILDREN_SHORT_V1: "Дети Экспресс",
  C_CHILDREN_SHORT_COMMENT_V1: " (включает: виртуальный 3D план, не более 24 кап - одна или две челюсти)",
  C_RETAINERS: "Ретенционные капы",
  C_RETAINERS_COMMENT: " (включает 2 пары)",
  C_RETAINERS_COMMENT_V2: " (включает 2 пары)",
  C_RETAINERS_V2: "Ретенционные капы",
  RETAINERS: "Ретенционные капы (2 пары)",
  RETAINERS_ONE: "Ретенционные капы (1 пара)",
  RETAINERS_ONE_ARCH: "Ретенционные капы",
  RETAINERS_LOWER_ARCH: "Ретенционные капы для нижней челюсти (2)",
  RETAINERS_LOWER_ARCH_ONE: "Ретенционные капы для нижней челюсти (1)",
  RETAINERS_QUANTITY: "Количество комплектов ретейнеров",
  RETAINERS_UPPER_ARCH: "Ретенционные капы для верхней челюсти (2)",
  RETAINERS_UPPER_ARCH_ONE: "Ретенционные капы для верхней челюсти (1)",
  C_3DSMILE_PRO: "3D Smile Pro",
  C_3DSMILE_PRO_COMMENT:
    "Данный курс действует для врачей любой специальности, студентов медицинских ВУЗов, сотрудников стоматологических клиник (включает: глубокий анализ КТ, виртуальный 3D план движения корней и коронок, 3 коррекции, неограниченное количество этапов)",
  CBCT_ANALYSIS: "Глубокий анализ КТ",
  CBCT_ANALYSIS_3D_PLAN: "Глубокий анализ КТ",
  DELIVERY: "Доставка",
  C_ANTHROPOMETRIC_DIAGNOSTIC:
    "Антропометрический анализ 3D-моделей  (протокол антропометрической диагностики МГМСУ) - 5000 руб",
  C_ANTHROPOMETRIC_DIAGNOSTIC_ITEM:
    "Антропометрический анализ 3D-моделей  (протокол антропометрической диагностики МГМСУ)",
  C_IMPRESSIONS: "Я буду использовать слепки",
  C_SCAN: "Я буду использовать скан",
  C_YES_CT_CORRECTION: "Коррекция будет с КТ",
  C_NO_CT_CORRECTION: "Коррекция будет без КТ",
  C_DEEP_CBCT_ANALYSIS: "Глубокий анализ КТ - 26 000 руб.",
  C_DEEP_CBCT_ANALYSIS_ITEM: "Глубокий анализ КТ",
  HEADER_ARCHES: "Зубные дуги",
  ARCHES_SELECT: "Необходимо лечить зубные дуги",
  TA_BOTH: "Обе",
  TA_UPPER: "Верхняя",
  TA_LOWER: "Нижняя",
  TA_BOTH_RETAINERS_USA: " ",
  TA_UPPER_RETAINERS_USA: " ",
  TA_LOWER_RETAINERS_USA: " ",
  ARCHES_U_SELECT: "Форма дуги верхнего зубного ряда",
  STAGE: "Этап",
  AMOUNT: "Количество",
  UPPER_ARCH: "для верхней дуги",
  LOWER_ARCH: "для нижней дуги",
  SELECT_TREAT_ARCHES: "Выберите зубные дуги",
  TA_U_MAINTAIN: "Не менять",
  TA_U_EXPAND: "Расширить",
  TA_U_NARROW: "Сузить",
  TA_U_EXPAND_TYPE_TORQUE: "Торковое",
  TA_U_EXPAND_TYPE_BODILY: "Корпусное",
  TA_U_EXPAND_TYPE_HOWTO: "За счет чего",
  TA_U_CORRECTION_BOTH: "расширение торковое и корпусное",
  TA_U_CORRECTION_TORQUE: "расширение торковое",
  TA_U_CORRECTION_BODILY: "расширение корпусное",
  ARCHES_L_SELECT: "Форма дуги нижнего зубного ряда",
  TA_L_MAINTAIN: "Не менять",
  TA_L_EXPAND: "Расширить",
  TA_L_NARROW: "Сузить",
  ARCHES_TLA: "Выравнивание зубов",
  TLA_G_MARGIN: "По десневому краю",
  TLA_C_EDGE: "По режущему краю",
  HEADER_INCISORS: "Соотношение резцов",
  INCISORS_VO: "Соотношение резцов по вертикали",
  VO_MAINTAIN: "Не менять",
  VO_DECREASE: "Уменьшить резцовое перекрытиe",
  VO_INCREASE: "Увеличить резцовоe перекрытиe",
  VO_COMMENT: "За счет чего изменить глубину",
  VO_COMMENT_PLACEHOLDER: " ",
  INCISORS_HO: "Соотношение резцов по сагиттали",
  INCISORS_HO_U: "Верхние резцы",
  HO_U_MAINTAIN: "Не менять",
  HO_U_RETROCLINATE: "Устранить протрузию",
  HO_U_PROCLINATE: "Устранить ретрузию",
  INCISORS_HO_L: "Нижние резцы",
  HO_L_MAINTAIN: "Не менять",
  HO_L_RETROCLINATE: "Устранить протрузию",
  HO_L_PROCLINATE: "Устранить ретрузию",
  OVERJET: "Сагиттальная щель",
  OJ_MAINTAIN: "Не менять",
  OJ_CONTACT: "Установить резцы в контакт",
  OJ_MAINTAIN_LARGE: "Оставить сагиттальную щель",
  OJ_MAINTAIN_CORRECT: " ",
  OJ_MAINTAIN_CORRECT_SPECIFY: " ",
  CORRECT_RETROCLINATION_UPPER: " ",
  CORRECT_PROCLINATION_LOWER: " ",
  CORRECT_PROCLINATION_UPPER: " ",
  CORRECT_RETROCLINATION_LOWER: " ",
  MIDLINE: "Соотношение резцов по трансверзали (средняя линия)",
  ML_MAINTAIN: "Не менять",
  ML_ADJUST_LOWER_TO_UPPER: " ",
  ML_ADJUST_UPPER_TO_LOWER: " ",
  ML_IMPROVE: "Улучшить (за счет каких движений)",
  HEADER_CANINE: "Соотношение клыков",
  CANINE_RIGHT: "Справа",
  CR_R_I_CLASS: "I класс",
  CR_R_II_CLASS: "II класс",
  CR_R_III_CLASS: "III класс",
  CANINE_LEFT: "Слева",
  CR_L_I_CLASS: "I класс",
  CR_L_II_CLASS: "II класс",
  CR_L_III_CLASS: "III класс",
  CANINE_HOWTO: "За счет чего",
  CRH_DISTALIZE: "Дистализация",
  CRH_MESIALIZE: "Мезиализация",
  CRH_IPR: "Сепарация (IPR)",
  CRH_AI: " ",
  CRH_SPECIFY: " ",
  HEADER_MOLAR: "Соотношение первых моляров",
  MOLAR_RIGHT: "Справа",
  MR_R_MAINTAIN: "Не менять",
  MR_R_I_CLASS: "I класс",
  MR_R_II_CLASS: "II класс",
  MR_R_III_CLASS: "III класс",
  MOLAR_LEFT: "Слева",
  MR_L_I_CLASS: "I класс",
  MR_L_II_CLASS: "II класс",
  MR_L_III_CLASS: "III класс",
  MOLAR_HOWTO: "За счет чего",
  MRH_DISTALIZE: "Дистализация",
  MRH_MESIALIZE: "Мезиализация",
  MRH_IPR: "Сепарация (IPR)",
  MRH_AI: " ",
  IPR_TRUE: "Использовать IPR",
  HEADER_ELASTICS: "Использование эластичной тяги",
  ELASTICS_RIGHT: "Справа",
  ER_R_I_CLASS: "I класс",
  ER_R_II_CLASS: "II класс",
  ER_R_III_CLASS: "III класс",
  ER_NONE: "Не использовать",
  ELASTICS_LEFT: "Слева",
  ER_L_I_CLASS: "I класс",
  ER_L_II_CLASS: "II класс",
  ER_L_III_CLASS: "III класс",
  BUTTONS_AND_HOOKS: "Использовать крючки или кнопки",
  HOOK: "Крючок",
  BUTTON: "Кнопка",
  CANCEL : "нажмите для отмены",
  HEADER_CLOSE_ALL: "Закрыть все промежутки",
  CAG_YES: "Да",
  CAG_MAINTAIN: "Сохранить диастему / тремы",
  CAG_LEAVE_SPACE:
    "Сохранить класс соотношения зубов, при этом оставить промежуток/промежутки :",
  CAG_LEAVE_SPACE_FOR               : "Оставить место под",
  CAG_LEAVE_SPACE_ADD_COMMENT       : "Введите комментарий",
  CAG_LEAVE_SPACE_COMMENT           : "(укажите номер зуба и расстояние в миллиметрах)",
  TOOTH_NUMBER                      : "зуб",
  DISTANCE_IN_MILLIMETRES           : "расстояние в миллиметрах",
  MILLIMETRES                       : "мм",
  ADD_FIELD                         : "Добавить поле",
  EXTRA_NOMOVE:
    "Не перемещать следующие зубы (мосты, имплантаты, анкилозированные зубы, другое)",
  EXTRA_NOLOCK: "Избегать установку замков на следующих зубах (коронки, другое)",
  EXTRA_EXTRACT: "Удалить следующие зубы",
  EXTRA_HOOK: "Отметьте зуб галочкой, где будет установлен крючок",
  EXTRA_BUTTON: "Отметьте зуб галочкой, где будет установлена кнопка",
  EXTRA_TEETHING: "Оставить место для прорезывания зубов",
  EXTRA_COMMENTS:
    "Комментарии (какой результат Вы хотите получить), прочие инструкции ",
  EXTRA_COMMENTS_PLACEHOLDER: "Комментарии",
  treatment_goals_ex: "примеры",
  treatment_goals_ex_1: " ",
  treatment_goals_ex_2: " ",
  treatment_goals_ex_3: " ",
  UPLOADED_FILES: "Загруженные STL, фото, ОПТГ и другие файлы",
  UPLOAD_FHOTOS : "Загрузите фотографии до лечения",
  UPLOADED_CBCT: "Загруженные файлы КТ",
  CBCT_LINKS:    "Ссылки на КТ",
  LINK:         "Ссылка",
  CREATED_AT:    "Добавлена",
  OPEN:          "Копировать",
  UPLOADED_3SHAPE: "Подключиться к 3Shape",
  UPLOADED_MEDIT: "Подключиться к Medit",
  UPLOADED_PREXION : "Подключиться к Prexion",
  COMING_SOON: " ",
  UPLOADED_ITERO : "Подключиться к iTero",
  ITERO_SUPPORT : " ",
  ITERO_PHONE : " ",
  ITERO_ID : " ",
  LINK_TO_MANUAL : "Инструкция",
  UPLOAD_FILES: "Загрузите STL, фото, ОПТГ и другие файлы",
  UPLOASES_FILES_EXIST : " ",
  ADD_LINK_TO_CT: "Ссылка на КТ",
  UPLOAD_BTN: "Загрузите фото",
  UPLOAD_FILE_BTN: "Загрузить файл",
  DISK_CBCT: "КТ на диске",
  PHOTO_PROFILE: "Фото в профиль",
  PHOTO_SMILE: "Фото анфас с улыбкой",
  PHOTO_PORTRATE: "Фото анфас без улыбки",
  PHOTO_UPPER_OCCLUSAL: "Окклюзионный вид верхнего зубного ряда",
  PHOTO_LOWER_OCCLUSAL: "Окклюзионный вид нижнего зубного ряда",
  PHOTO_RIGHT: "Латеральный вид справа",
  PHOTO_FRONT: "Фронтальный вид",
  PHOTO_LEFT: "Латеральный вид слева",
  PHOTO_BEFORE: "До лечения",
  PHOTO_CORRECTION: "Коррекция",
  BTN_ADD_FILES: "Добавить файлы",
  BTN_CHOOSE_FILES: "Выбрать файлы",
  BTN_CHOOSE_PHOTO: "Выбрать фото",
  BTN_SUBMIT: "Отправить",
  BTN_SUBMIT_NEW: "Отправить в работу",
  BTN_EDIT: "Редактировать",
  BTN_CHANGE_PASSWORD: "Изменить пароль",
  BTN_SAVE: "Сохранить",
  BTN_SAVE_DRAFT: "Сохранить черновик",
  BTN_SAVE_DRAFT_CONTINUE_LATER: "Хотите сохранить данные пациента в черновиках и продолжить заполнение позже?",
  BTN_SAVE_CONTINUE_LATER: "Хотите сохранить данные пациента и продолжить заполнение позже?",
  REQUIRED_FIELDS_FOR_A_DRAFT: "Пожалуйста, заполните обязательные поля",
  EXIT_WITHOUT_SAVING: "Выйти без сохранения?",
  STAY_HERE: "Остаться здесь",
  LEAVE_WITHOUT_SAVING_BACK_BTN: "Изменения не будут сохранены",
  CBCT_UPLOAD_HEADER: "Загрузите файлы КТ",
  CBCT_UPLOADED_HEADER: "Загруженные файлы",
  HOW_UPLOAD_CBCT : " ",
  CBCT_DETAILS : "инструкция по загрузке файлов",
  CBCT_UPLOAD_PROGRESS_TITLE: "Загружается {filesCount} файл(-ов)",
  CBCT_UPLOAD_COMPLETE: "Загрузка завершена",
  CBCT_UPLOAD_ERROR: "Ошибка загрузки",
  SCAN_UPLOAD_SCAN_WARNING: "Не забудьте загрузить скан",
  SCAN_UPLOAD_CT_WARNING: "Не забудьте загрузить КТ",
  "3D_SMILE_PRO_WARNING":
    "Необходимо загрузить справку пациента с места работы/учебы, подтверждающую его должность врача/сотрудника стоматологической клиники или статус студента медицинского ВУЗа.",
  ADDITIONAL_ALIGNERS: "Дубликаты кап",
  SAA_PROCESSING: "Заявка обрабатывается",
  SAA_READY: "Дубликаты кап готовы",
  "all.additional.aligners": "смотреть все дубликаты",

  // Footer
  "page.footer.header": "© ООО «ДОММАР»",
  "page.footer.header.usa": "© 3D Smile USA Inc.",
  "page.footer.content": "3D Smile. Высокие технологии движения зубов!",
  "page.footer.content.usa":
    "3D Predict. See the unseen. Look beyond the evident.",
  "page.footer.contacts": "Контакты",
  "page.footer.payment": "Оплата",
  "plan.approve.header": "Утверждение 3D-плана",
  "plan.approve.body": "Утвердить план:",
  "plan.approve.btn": "Утвердить",
  "plan.confirm_plan.btn": "Утвердить",
  "plan.approve.no_clinic": "В профиле пациента не указана клиника.",
  "plan.approve.redirect": "Перейти в профиль",
  "plan.approve.accept": "Я прочитал и согласен с условиями",
  "plan.approve.postpone": "Отложить утверждение",
  "plan.rework.header": "Доработка 3D-плана",
  "plan.rework.body": "Номер плана:",
  "plan.rework.btn": "Отправить на доработку",
  "plan.cancel.header": "Скорректировать 3D-план",
  "plan.cancel.body": "План:",
  "plan.cancel.stage": "Номер этапа",
  "plan.cancel.scroll.to.field": "Нажмите, чтобы перейти к полю",
  "plan.cancel.btn": "Скорректировать план",
  "plan.order.btn": "Заказать",
  "change.password": "Изменить пароль",
  "change.password.match": "Пароли не совпадают",
  "enter.password": "Введите новый пароль",
  "re-enter.password": "Повторите новый пароль",
  "treatment.correct.header": "Скорректировать лечение",
  "treatment.additional_course.header": "Дополнительный комплект",
  "correction.ct.notifcation": "Загружен КТ файл",
  "recipe.check.reference.steps"      : "Номер этапа",
  "recipe.check.reference.photos"     : "Фотографии пациента",
  "recipe.check.reference.material"   : "Использовать скан или слепки",
  "recipe.check.reference.arches"     : "Зубные дуги",
  "recipe.check.reference.correction_with_ct": "Коррекция с КТ или без",

  "Ляшук Даниил": "Ляшук Даниил",
  "Аптекарев Федор": "Аптекарев Федор",
  "Дмитриенко Ирина": "Дмитриенко Ирина",
  "Сосин Михаил": "Сосин Михаил",

  "notify.doctor.update.success": "Информация обновлена",
  "notify.doctor.update.error":
    "Пользователь с таким логином или email уже зарегистрирован",
  "notify.doctor.password.success": "Пароль успешно изменен",
  "notify.doctor.password.failed": "При изменении пароля произошла ошибка",
  "notify.comment": "Комментарий добавлен",
  "notify.comment.error": "При сохранении комментария произошла ошибка",
  "notify.question": "Вопрос отправлен Клиническому эксперту",
  "notify.question.error": "При отправлении вопроса возникла ошибка",

  "notify.patient.create.ok": "Новый пациент успешно создан",
  "notify.patient.create.draft.patient": "Ваш пациент ",
  "notify.patient.create.draft.ok": " был сохранен в черновиках, Вы можете продолжить заполнение рецепта во вкладке 'Пациенты'",
  "notify.patient.create.error": "Ошибка создания пациента",
  "notify.patient.update.ok": "Пациент успешно изменен",
  "notify.patient.update.error": "Ошибка изменения пациента",

  "notify.add_clinic.ok": "Клиника добавлена",
  "notify.add_clinic.error": "При добавлении клиники произошла ошибка",

  "notify.unlink_clinic.ok": "Клиника удалена",
  "notify.unlink_clinic.error": "При удалении клиники произошла ошибка",

  "notify.correction.create.ok": "Коррекция создана",
  "notify.form.submitted.ok"   : "мы получили Ваш заказ и приступаем к работе над ним.",
  "notify.correction.create.error": "При добавлении коррекции произошла ошибка",
  "notify.correction.thanks":
    "Спасибо что воспользовались новой формой заказа коррекции. Ждём ваши отзывы по адресу feedback@3d-smile.com",

  "notify.status_end.ok": "Статус пациента переведен на Лечение завершено",
  "notify.status_end.error": "При попытке завершить лечение произошла ошибка",

  "notify.retainers.ok": "Ретейнеры заказаны",
  "notify.retainers.error": "Ошибка заказа ретейнеров",

  "notify.additional.aligners.ok": "Дубликаты заказаны",
  "notify.additional.aligners.error":
    "При заказе дубликатов произошла ошибка",

  "notify.approve.ok": "План утвержден",
  "notify.approve.error": "При утверждении 3D плана произошла ошибка",

  "notify.rework.ok": "Благодарим за комментарии. Техники {labelTitle} уже работают над обновлениями 3D плана",
  "notify.rework.error": "При отправлении плана в работу произошла ошибка",

  "notify.doctor.subscribe": "Уведомления",
  "notify.doctor.subscribe.edit": "Подписка на уведомления",

  "notify.link_copy": "Ссылка скопирована в буфер обмена",
  "notify.link_tg": "Ссылка отправлена в Telegram",
  "notify.link_email": "Ссылка отправлена на email",
  "notify.link_tg_email": "Ссылка отправлена в Telegram и на email",

  "notify.send_password.EMAIL_SENT":
    "Одноразовый пароль отправлен на электронную почту",
  "notify.send_password.E_EMAIL_OR_LOGIN_REQUIRED":
    "Укажите адрес электронной почты или логин",
  "notify.send_password.E_EMAIL_NOT_FOUND": "Адрес электронной почты не найден",
  "notify.send_password.E_LOGIN_NOT_FOUND": "Логин не найден",
  "notify.send_password.SENDING_ERROR": "Ошибка при отправлении ссылки для восстановления. Проверьте введеные данные.",
  "notify.send_password.SENDING_RESTORE_SUCCESS"   : "Ссылка для восстановления пароля была отправлена на почту",

  "notify.login.SUCCESS": "SUCCESS",
  "notify.login.E_PASSWORD_RESET": "Необходимо задать новый пароль",
  "notify.login.E_INVALID_AUTH": "Неверный логин или пароль",

  "notify.upload.E_UNSUPPORTED_FORMAT":
    "Формат файла, который вы загружаете, не поддерживается. Пожалуйста, загрузите файл в формате JPEG.",
  "notify.upload.E_UNSUPPORTED_FORMAT_EXT":
    "Формат файла, который вы загружаете, не поддерживается. Пожалуйста, загрузите файл в одном из следующих форматов: JPG, JPEG, PNG, STL, PDF, ORTHO, MP4.",
  "notify.upload.E_UNSUPPORTED_FORMAT_CBCT":
    "Формат файла, который вы загружаете, не поддерживается. Пожалуйста, загрузите файл в одном из следующих форматов: ZIP, RAR.",
  "notify.upload.E_UNSUPPORTED_HIEF":
    "Вы пытаетесь загрузить файл формата HEIF. Этот формат не поддерживается. Пожалуйста, настройте фотоаппарат в телефоне на сохранение фотографий в формате JPEG (Настройки -> Камера -> Формат -> Наиболее совместимые) и загрузите файл в формате JPEG.",
  "notify.upload.E_FILE_TOO_BIG_CBCT":
    "Размер загружаемых файлов не должен превышать 1.5 Гб.",

  "new_password.header":
    "Вы вошли с одноразовым паролем, пожалуйста задайте новый пароль.",
  "new_password.header_key":
    "Пожалуйста введите пароль, содержащий не менее 10 символов, латинские большие и маленькие буквы, цифры и специальные символы",
  "new_password.new_password": "Новый пароль",
  "new_password.confirm": "Подтверждение пароля",
  "new_password.button": "Сохранить",

  Privacy: "Политика конфиденциальности",

  "nocorrection"         : "Рецепт указан в комментарии (см. выше)",
  "correction"           : "Коррекция",
  "main.treatment"       : "Основное лечение",
  "correction.impressions": "Слепки",
  "correction.material": "Слепки",
  "correction.impressions.new": "Снимаю новые слепки / скан",
  "correction.impressions.old": "Без слепков (на основании последнего этапа)",
  "correction.reason": "Причина коррекции",
  "correction.failed.teeth": "На каких зубах движение не осуществилось",
  "correction.plan_type": "По какому плану продолжить лечение",
  "correction.old_plan": "Продолжить по предыдущему рецепту",
  "correction.new_plan": "Скорректировать рецепт",
  "correction.information_correct": "Информация введена верно",

  "correction.comment.title": "Заказ коррекции",
  "correction.comment.plan": "План:",
  "correction.comment.tooth": "Зуб",
  "correction.comment.stage": "Этап:",
  "correction.comment.arches.both": "Обе челюсти",
  "correction.comment.arches.upper": "Верхняя челюсть",
  "correction.comment.arches.lower": "Нижняя челюсть",
  "correction.comment.casts.new": "Будут подготовлены новые слепки",
  "correction.comment.casts.old": "Слепки на основании последнего этапа",
  "correction.comment.impressions.scan":
    "Будут подготовлены новые слепки (3D скан)",
  "correction.comment.impressions.impression":
    "Будут подготовлены новые слепки (слепки)",
  "correction.comment.movment_1": "Не соблюдался режим ношения кап",
  "correction.comment.movment_2": "Не осуществилось движение",
  "correction.comment.movment_type_0": "не выбрано движение",
  "correction.comment.movment_type_1": "интрузия",
  "correction.comment.movment_type_2": "экструзия",
  "correction.comment.movment_type_3": "ротация",
  "correction.comment.movment_type_4": "мезиализация",
  "correction.comment.movment_type_5": "дистализация",
  "correction.comment.movment_type_6": "торк",
  "correction.comment.movment_type_7": "тип (ангуляция)",
  "correction.comment.movment_type_8": "вестибулярное",
  "correction.comment.movment_type_9": "оральное",
  "correction.comment.correct_plan_1":
    "Продолжить лечение по предыдущему рецепту",
  "correction.comment.correct_plan_2": "Скорректировать рецепт",

  DENTAL_NOTATION_HEADER: "Схема нумерации зубов",
  DENTAL_NOTATION_TYPE_FDI: "Международная (FDI)",
  DENTAL_NOTATION_TYPE_PALMER: "Нотация Палмера",
  DENTAL_NOTATION_TYPE_UNIVERSAL: "Универсальная",

  preferences: "Клинические предпочтения",
  "preferences.ipr": "Сепарация",
  "preferences.ipr_enabled": "Проводить сепарацию",
  "preferences.ipr_start_step": "Начинать сепарацию с этапа",
  "preferences.attachments": "Замки",
  "preferences.attachments_delay": "Установить замки на этапе",
  "preferences.attachments_deprecated_teeth":
    "Не устанавливать замки на отмеченных зубах",
  "preferences.expansion": "Расширение",
  "preferences.always_expand": "Всегда расширять",
  "preferences.incisor_relationship": "Соотношение резцов",
  "preferences.incisor_relationship.overjet":
    "Соотношение резцов по горизонтали, мм",
  "preferences.incisor_relationship.overbite":
    "Соотношение резцов по вертикали, мм",
  "preferences.upper_incisor_angulation":
    "Торк верхних резцов (укажите в градусах)",
  "preferences.additional_notes": "Дополнительно",
  "preferences.additional_notes.sub":
    "Дополнительные клинические предпочтения для большинства пациентов",
  "preferences.overcorrection": "Гиперкоррекция",
  "preferences.deep_bite_intrusion": "Интрузия при глубоком прикусе + 1 мм",
  "preferences.incisors_extrusion": "Экструзия резцов + 1 мм",
  "preferences.rotation_over_30dg": "Ротация более 30 градусов +5 градусов",

  "profile.unable_change_email":
    "Please contact us at support@3dpredict.com or contact your manager if you would like to change your email.",
  "comment.message.on.plan.approve":
    "Вы можете оставить комментарий по предоставленному 3D-плану в момент просмотра",

  "comment.on.3dplan.adjustment": "Комментарий к плану версии",
  COMMENT_TYPE_CE_QUESTION: "Вопрос клиническому эксперту",
  COMMENT_TYPE_CORRECTION: "Коррекционный рецепт",
  COMMENT_TYPE_REWORK: "Комментарий к плану",
  COMMENT_TYPE_REWORK_PRESICE : "Комментарий к плану / 3D Precise",
  COMMENT_TYPE_ORDER_RETAINERS: " ",

  "offer.header": "Оферта",
  "offer.accept": "Я прочитал и согласен с условиями",
  "offer.confirm_plan.btn": "Подписать",
  "offer.pdf_error": "Что-то пошло не так во время загрузки офферты",
  "notify.doctor.offer.error": "При утверждении оферты произошла ошибка",
  "notify.doctor.offer.success": "Договор оферты подписан",
  "page.footer.offer": "Оферта",
  "offer.predict.header": "3D Predict's Terms and Conditions",
  "offer.accept.predict":
    "I confirm that I have read, understand and agree to 3D Predict's Terms and Conditions",
  "page.predict.footer.offer": "3D Predict's Terms and Conditions",

  "manuals.title": "Инструкции",
  "manuals.empty.query": "На вашем языке еще нет опубликованных инструкций.",
  "manuals.loading": "Загрузка...",
  "notify.patient.payment_link.error":
    "При переходе на страницу оплаты произошла ошибка",
  "patient.area.payments.paid": "Оплата проведена успешно",
  "another.payer.person":
    "Укажите ФИО плательщика, если плательщиком не является сам пациент",
  PAYER_LAST_NAME: "Фамилия",
  PAYER_LAST_NAME_PLACEHOLDER: "Фамилия плательщика",
  PAYER_FIRST_NAME: "Имя",
  PAYER_FIRST_NAME_PLACEHOLDER: "Имя плательщика",
  PAYER_MIDDLE_NAME: "Отчество",
  PAYER_MIDDLE_NAME_PLACEHOLDER: "Отчество плательщика",

  E_LOGIN_REQUIRED: " ",
  EXTRA_SERVICE_OPTION_LAST_STAGE: "Капы по последнему этапу",
  EXTRA_SERVICE_OPTION_USE_SCAN: "Я буду использовать скан",
  EXTRA_SERVICE_OPTION_USE_IMPRESSIONS: "Я буду использовать слепки",

  //Doctor payments info
  "payments.account.delete.err": "При удалении данных произошла ошибка",
  "payments.doctor.total": "Итого к оплате:",

  //Additional Aligners Table
  "th.stage": "Этап",
  "th.amount": "Количество",
  "th.option": "Челюсть",
  "th.status": "Статус",
  "th.timestamp": "Дата заказа",

  "integrations.my.scanners": "Мои сканеры",
  "integration.my.scanners.description": "В этом разделе вы можете привязать аккаунты внешних сканеров к личному кабинету {labelTitle}",
  "pat.medit.auth": "Подключение к Medit",
  "pat.medit.auth.success" : 'Авторизация Medit прошла успешно',
  "medit.auth.err" : "При подключении к Medit произошла ошибка",
  "th.medit.patientId": "ID пациента",
  "th.medit.status" : "Статус",
  "th.medit.caseName" : "Название",
  "th.medit.patientName" : "Имя пациента",
  "th.medit.date.updated": "Дата редактирования",
  "th.medit.date.scanning": "Дата сканирования",
  "th.medit.date.ordered": "Дата создания",
  "th.medit.action": "Действия",
  "th.medit.action.submit": "Добавить нового пациента",
  "th.medit.action.connect": "Привязать к существующему пациенту",
  "th.medit.action.upload": "Загрузить файлы",
  "th.medit.case.connected.to": "Кейс привязан к ",
  "th.medit.case.reconnected.to": "Привязать к другому пациенту ",
  "th.medit.action.сhoose.file": "Выбрать файл",
  "th.medit.no.files": "Нет доступных файлов",
  "th.medit.action.сhoose.patient": "Выбрать пациента {labelTitle}",
  "th.medit.patient.create": "Создание нового пациента...",
  "th.medit.patient.connect": "Связывание пациента...",
  "th.medit.patient.connect.success": "Пациент успешно привязан",
  "th.medit.connect.patient": "Загрузка Medit файлов",
  "th.medit.file.upload_success": "Medit файлы успешно загружены",
  "th.medit.upload_success": "Medit файлы",
  "th.medit.file.upload_error": " не загрузились",
  "th.medit.file.name": "Файл ",
  "th.medit.file.uploading" : " загружается",
  "th.medit.file.uploaded" : " был загружен",
  "th.medit.uploaded.files": "Загруженные файлы",
  "th.medit.patient_name": "Имя пациента",
  "th.medit.action.disconnect": "Отвязать",
  "th.medit.action.close": "Закрыть",
  "th.medit.action.redirect": "Перейти на страницу рецепта",
  "medit.title" : "Список сканов Medit",
  "medit.patient.pick" : "Обновить",
  "medit.search.message": "Данные не найдены",
  "th.medit.first.note": "Обратите внимание, что данный Medit кейс привязан к пациенту {labelTitle}",
  "th.medit.second.note": " При загрузке файлов к новому пациенту старый пациент будет отвязан",
  "SCAN" : "Скан файл",
  "CAD"  : "CAD файл",
  "CAM"  : "CAM файл",
  "MILLING": "Milling файл",
  "ATTACHED": "Прикрепленный файл",
  "upload_files_to_patient_card" : "Загрузить файлы из Medit",
  "patient.search.placeholder": "Введите фамилию, имя или ID пациента для поиска",

  // 3SHAPE
  "pat.3shape.auth": "Подключение к 3Shape",
  "pat.3shape.auth.success": "Авторизация 3Shape прошла успешно",
  "th.3shape.patientName": "ФИО пациента",
  "th.3shape.threeShapeOrderNo": "Номер заказа",
  "th.3shape.receivedOn": "Получен",
  "th.3shape.version": "Версия",
  "3shape.auth.err": "При подключении к 3Shape произошла ошибка",
  "th.3shape.scan.source": "Сканер",
  "th.3shape.application": "Приложение",
  "th.3shape.files": "Файлы",
  "th.3shape.file.name": "Название",
  "th.3shape.file.status": "Статус",
  "th.3shape.file.upload": "Загрузить",
  "th.3shape.file.uploaded": "Загружен",
  "th.3shape.err": "Произошла ошибка",
  "3shape.files": "Файлы 3Shape",
  "3shape.patient.info": "Данные пациента 3Shape",
  "3shape.patient.pick":
    "Выберите в таблице пациента в 3Shape соответствующего",
  "3shape.upload.files.warning":
    "Загрузка файлов займет некоторое время, Вы можете продолжить работу в Личном кабинете, не дожидаясь окончания загрузки.",
  "3shape.search.message": "Данные не найдены",

  "bonus.patient": "Бонусный пациент",
  "bonus.patients": "Бонусные пациенты",
  "bonus.patients.total": "Пациенты:",
  "bonus.period": "Бонусный период",
  "bonus.all.cases": "Всего пациентов",
  "bonus.cases.left": "Добавлено",
  "bonus.cases.left.1": "из",
  "bonus.cases.left.2": "пациентов",
  "bonus.case.chosen": "Бонусный пациент выбран",
  "bonus.case.not.chosen": "Бонусный пациент не выбран",
  "bonus.patient.set.successfully": "Пациент отмечен как бонусный",
  "bonus.patient.set.error": "Ошибка при выборе бонусного пациента",
  "bonus.patient.btn": "Бонусный пациент",
  "bonus.patient.btn.confirm": "Выбрать бонусным",
  "bonus.patient.btn.description.1": "Вы уверены, что хотите выбрать пациента",
  "bonus.patient.btn.description.2": "бонусным",
  "bonus.period.end.days": "дней до конца бонусного периода",
  "bonus.period.end.months": "месяцев до конца бонусного периода",
  "bonus.patient.activation.one": "пациент до активации бонусного пациента",
  "bonus.patient.activation.more": "пациента до активации бонусного пациента",
  "bonus.patient.activation.a.lot": "пациентов до активации бонусного пациента",
  "bonus.full.coin.title": "Вы можете заказать бонусного пациента",

  // bonuses page
  "bonuses.page.title": "Программа лояльности",
  "bonuses.page.active.bonuses": "Активные смайлы",
  "bonuses.page.non.active.bonuses": "Списанные бонусы",
  "bonuses.page.expert.description":
    "1 смайл дает 1 скайп с экспертом.",
  "bonuses.page.video.description":
    "Для оплаты смайлами сообщите менеджеру при регистрации на мероприятие.",
  "bonuses.page.choose.event":
    "Выбрать мероприятие",
  "bonuses.page.last.description":
    "Оплатить смайлами можно не более 50% стоимости мероприятия.  ",

  "geo.usa.state": "Штат",
  "geo.usa.city": "Город",

  "login.error": "Неверный логин или пароль",
  "password.recovery.invalid.link": "Ссылка не является действительной",
  "password.recovery.title": "Восстановление пароля",

  "password.requirements.header": "Пожалуйста, введите пароль, который удовлетворяет требованиям",
  "password.requirements.digits": "содержит цифры",
  "password.requirements.upper.chars": "содержит прописные буквы латинского алфавита",
  "password.requirements.lower.chars": "содержит строчные буквы латинского алфавита ",
  "password.requirements.length": "длина не менее 10 символов",
  "password.requirements.special": "содержит специальные символы",
  "password.requirements.match": "введенные пароли совпадают",
  "password.requirements.similar": "не слишком похож на ваш логин",
  "public.plan.error": "При получении публичной ссылки произошла ошибка",

  "p.patient.correction.stages": "Этапов",
  "meetings.not.found": "Расписание не найдено",
  "meetings.not.found.description": "Здесь будут отображаться доступные для записи клинические эксперты",
  "meetings.error": "Не удалось загрузить расписание",
  "meetings.title": "Запись на встречу с клиническим экспертом",
  "meetings.book": "Записаться",
  "meetings.available": "Доступные записи",
  "meetings.booked": "Мои записи",
  "meetings.nearest_slot": "Ближайшая запись",
  "meetings.nearest_slot.empty": "Отсутствует",
  "meetings.choose.expert": "Выберите эксперта с которым хотите назначить встречу",
  "meetings.choose.expert.predict": "Выберите эксперта с которым хотите назначить встречу",
  "meetings.choose.expert.comment": " ",
  "meetings.feedback.ok": "Благодарим за обратную связь! Ваш отзыв отправлен",
  "meetings.feedback.title": "Отзыв о консультации",
  "meetings.feedback.comment": "Комментарий",
  "meetings.feedback.rating": "Оценка",
  "meetings.feedback.submit": "Отправить",
  "meetings.feedback.later": "Оставить позже",
  "meetings.feedback.description.1": "Уважаемый доктор",
  "meetings.feedback.description.2": "у Вас состоялся Zoom с клиническим экспертом 3D Smile",
  "meetings.feedback.description.p1": " по пациенту: ",
  "meetings.feedback.description.p2": " по пациентам: ",
  "meetings.feedback.description.3": "Пожалуйста, поделитесь обратной связью о предоставленной консультации, это поможет нам стать лучше для Вас.",

  "support.sidebar.title": "Контакты службы технической поддержки",
  "support.page.header": "Служба технической поддержки",
  "support.page.contacts.header": "Контакты службы технической поддержки",
  "support.page.toll.free": " ",
  "support.page.operating.hours": " ",
  "support.page.onbording.btn": " ",
  "support.page.onbording.btn.tooltip": " ",
  "support.page.zoom.call.title": " ",

  "additional.aligners.both.comment": "(опция включает две капы - одна для верхней и одна для нижней зубных дуг)",

  events: "Мероприятия",
  "events.upcoming": "Расписание",
  "events.upcoming.empty.heading": "Пока нет мероприятий",
  "events.upcoming.empty.description": "Здесь будут отображаться доступные к регистрации и покупке мероприятия",
  "events.enrolled": "Мои мероприятия",
  "events.enrolled.empty.heading": "Пока нет предстоящих или посещенных мероприятий",
  "events.enrolled.empty.description": "В этом разделе будут отображаться Ваши предстоящие и посещенные мероприятия",
  "events.enrolled.empty.link": "Перейти к расписанию",
  "events.recordings": "Медиатека",
  "events.recordings.empty.heading": "Пока нет видео",
  "events.recordings.empty.description": "Здесь будут отображаться приобретенные Вами записи мероприятий",
  "events.recordings.empty.link": "Перейти к расписанию",
  "events.search.placeholder": "Название",
  "events.filters": "Фильтры",
  "events.filters.reset": "Сбросить",
  "events.all": "Все",
  "events.meeting_type": "Формат",
  "events.meeting_type.offline": "Онлайн",
  "events.meeting_type.online": "Очное",
  "events.meeting_type.recording": "Видео",
  "events.price": "Стоимость",
  "events.price.free": "Бесплатное",
  "events.price.paid": "Платное",
  "events.training_level": "Уровень подготовки",
  "events.training_level.0": "Начальный",
  "events.training_level.1": "Базовый",
  "events.training_level.2": "Продвинутый",
  "events.city": "Город",
  "events.not_found.heading": "Мероприятия не найдены",
  "events.not_found.description": "К сожалению, мы не смоги найти мероприятий по вашему поиску",
  "events.recording": "Видео",
  "events.currency": "руб.",
  "events.free": "Бесплатно",
  "events.more_details": "Подробнее",
  "events.action.pay": "Оплатить",
  "events.action.pay.error": "Что-то пошло не так во время получения ссылки для оплаты. " +
                             "Попробуйте повторить попытку позже",
  "events.action.zoom_join": "Подключиться",
  "events.action.buy": "Купить",
  "events.action.get": "Получить",
  "events.action.signup": "Регистрация",
  "events.action.watch": "Смотреть",
  "events.action.feedback": "Оставить отзыв",
  "events.badge": "неоплаченных мероприятий",
  "events.video_available": "Доступно видео",
  "events.watch_modal.title": "Перейти на {service}?",
  "events.watch_modal.description": "Вы будете перенаправлены на {service} для просмотра видеозаписи",
  "events.watch_modal.open": "Перейти",
  "events.watch_modal.cancel": "Отмена",
  "events.zoom_modal.title": "Перейти в Zoom?",
  "events.zoom_modal.description": "Вы будете перенаправлены в Zoom для подключения к встрече",
  "events.error.heading": "Не удалось загрузить мероприятия",
  "events.error.description": "Что-то пошло не так. Попробуйте перезагрузить страницу или повторить попытку позже",
  "events.error.reset": "Повторить",
  "event.not.found": "Мероприятие не найдено",
  "events.rates.complimentary": "Бесплатное",
  "events.rates.price": "Стоимость",
  "events.rates.price.package": "Стоимость за цикл",
  "events.package.list": "Мероприятия, входящие в цикл",
  "events.payments.package.pay": "Оплатить цикл мероприятий",
  "events.enroll.success": "Вы успешно зарегистрированы на мероприятие",
  "events.enroll.error": "При попытке записи на мероприятие прозошла ошибка",
  "event.button.register": "Регистрация",
  "event.button.zoom_join": "Подключиться в Zoom",
  "event.payments": "Оплата",
  "event.payments.money": "Итоговая стоимость мероприятия",
  "event.payments.smiles": "Оплата смайлами",
  "event.start.datetime": "Дата и время",
  "event.payment.smiles.total": 'Количество "Смайлов" к списанию',
  "events.payments.link.error": "Произошла ошибка при попытке перехода на страницу оплаты",
  "event.registration.payment.status": "Статус",
  "event.registration.CREATED": "Заявка создана",
  "event.registration.PROCESSING": "Ожидание оплаты",
  "event.registration.PENDING": "Ожидание оплаты",
  "event.registration.DECLINED": "Ошибка при оплате",
  "event.registration.SUCCESS": "Оплачено",
  "event.registration.CANCELED": "Заявка отменена",
  "event.registration.enrolled": "Участвую",
  "event.meeting_link": "Ссылка для встречи",
  "events.leave.feedback": "Расскажите о ваших впечатлениях оставив отзыв о мероприятии",
  "events.send.feedback.error": "При отправлении отзыва произошла ошибка",
  "events.send.feedback.thanks": "Спасибо за оставленный отзыв",
  "events.send.feedback.success": "Ваш отзыв успешно отправлен. Спасибо за обратную связь!",
  "events.feedback.error": "Информация о мероприятии не найдена",
  "events.payment.legal_entity": "Наименование юридического лица",
  "events.payment.email": "Email",
  "events.payment.email.hint": "(Email адрес для направления договора)",
  "events.payment.email.doctor.hint": "(будет сохранен в Вашем профиле)",
  "events.payment.invoice.hint": "(для оплаты юридическим лицом)",
  "events.offer.sign": "Я прочитал и согласен",
  "events.registration.completed": "Нет мест",
  "events.registration.completed.verbose": "Не осталось свободных мест",
  "events.payment_status.goto": "Перейти к мероприятию",

  //tour
  "tour.take.tour" : " ",
  "tour.account"   : " ",
  "tour.scanners"  : " ",
  "tour.patient.new": " ",
  "tour.patients"  : " ",
  "tour.payment"   : " ",
  "tour.chat"      : " ",
};

export default messages;
