import { AcademicCapIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { getBanners } from "~/actions/banners";
import scanner from "~/assets/img/scanner.svg";
import shipmentsIcon from "~/assets/img/shipment_1.svg";
import { deployedRussia, deployedUSA, isDevelopment } from "~/common/utils";
import RenderBanners from "~/components/promotion/banners";
import { Badge } from "~/components/ui/badge";
import { usePaymentsCount, useShipmentsCount, useUnpaidEventsCount } from "~/hooks/use-user";
import { useAppDispatch, useAppSelector } from "~/store";

export default function PatientMenu() {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const tour = useAppSelector((state) => state.tour);
  const banners = useAppSelector((state) => state.banners);
  const bannersLeft = banners.filter((banner) => banner.align == "left");

  const menuRef = useRef<HTMLUListElement>(null);

  const numberOfPayments = usePaymentsCount();
  const numberOfShipments = useShipmentsCount();
  const numberOfUnpaidEvents = useUnpaidEventsCount();

  useEffect(() => {
    void dispatch(getBanners());
    const pathname = window.location.pathname;

    menuRef.current?.querySelectorAll("a").forEach((anchorEl) => {
      if (anchorEl.getAttribute("href") == pathname) {
        anchorEl.closest("li")?.classList.add("active");
      }
    });
  }, [dispatch]);

  return (
    <div className="page-sidebar-wrapper">
      <aside
        id="patient-menu-sidebar"
        className={tour ? "page-sidebar collapse show" : "page-sidebar navbar-collapse collapse"}
        style={{ top: isMobile ? 10 : 0 }}
      >
        {isMobile &&
        bannersLeft.length > 0 &&
        (deployedUSA() ||
          (deployedRussia() && (window.location.hostname.endsWith(".ru") || isDevelopment))) ? (
          <RenderBanners banners={bannersLeft} mobile={true} />
        ) : null}

        <ul
          ref={menuRef}
          className="page-sidebar-menu"
          data-keep-expanded="false"
          data-auto-scroll="true"
          data-slide-speed={200}
        >
          <li className="nav-item start active">
            <a
              href="#"
              className="nav-link nav-toggle focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600"
              id="sidebar"
            >
              <i aria-hidden className="icon-home" />
              <FormattedMessage id="left.menu.header" />
            </a>

            <ul className="sub-menu" id="sidebar-submenu">
              <PatientMenuItem
                id="sidebar-patients"
                to="/pages/patients"
                icon={<i aria-hidden className="icon-users" />}
                title={<FormattedMessage id="left.menu.patients" />}
              />

              {deployedRussia() ? (
                <PatientMenuItem
                  to="/pages/shipments"
                  icon={<img style={{ width: 30 }} src={shipmentsIcon} alt="" />}
                  title={<FormattedMessage id="left.menu.shipments" />}
                  badge={numberOfShipments}
                />
              ) : null}

              <PatientMenuItem
                to="/pages/new_patient"
                id="sidebar-patient-add"
                icon={<i aria-hidden className="icon-user-follow" />}
                title={<FormattedMessage id="left.menu.add_patient" />}
              />

              {deployedRussia() && intl.locale == "ru" ? (
                <PatientMenuItem
                  to="/pages/payments"
                  icon={<i aria-hidden className="icon-wallet" />}
                  title={<FormattedMessage id="left.menu.my_payments" />}
                  badge={numberOfPayments}
                />
              ) : null}

              {deployedUSA() ? (
                <PatientMenuItem
                  id="sidebar-scanners"
                  to="/pages/scanners"
                  icon={<img style={{ width: 35, marginLeft: -8 }} src={scanner} alt="" />}
                  title={<FormattedMessage id="integrations.my.scanners" />}
                />
              ) : null}

              {deployedRussia() ? (
                <PatientMenuItem
                  to="/pages/events"
                  badge={numberOfUnpaidEvents}
                  icon={
                    <AcademicCapIcon className="tw-h-5 tw-w-5 tw-align-bottom tw-text-[#a7bdcd] group-hover:tw-text-[#5b9bd1]" />
                  }
                  title={<FormattedMessage id="events" />}
                />
              ) : null}

              {deployedUSA() ? (
                <PatientMenuItem
                  id="sidebar-meetings"
                  to="/pages/support"
                  icon={<i aria-hidden className="icon-call-out" />}
                  title={<FormattedMessage id="support.sidebar.title" />}
                />
              ) : null}

              <PatientMenuItem
                id="sidebar-meetings"
                to="/pages/meetings"
                icon={<i aria-hidden className="icon-calendar" />}
                title={<FormattedMessage id="meetings.title" />}
              />

              <PatientMenuItem
                id="sidebar-instructions"
                to="/pages/manuals"
                icon={<i aria-hidden className="icon-notebook" />}
                title={<FormattedMessage id="manuals.title" />}
              />

              {intl.locale == "ru" ? (
                <PatientMenuItem
                  id="sidebar-calendar"
                  to="https://smile-crm-object-storage.storage.yandexcloud.net/application/public/static/promotion/3DSmileCases.pdf"
                  icon={<i aria-hidden className="icon-doc" />}
                  title={<FormattedMessage id="left.menu.library" />}
                />
              ) : null}
            </ul>
          </li>
        </ul>

        {!isMobile &&
        bannersLeft.length > 0 &&
        (deployedUSA() ||
          (deployedRussia() && (window.location.hostname.endsWith(".ru") || isDevelopment))) ? (
          <RenderBanners banners={bannersLeft} />
        ) : null}
      </aside>
    </div>
  );
}

function PatientMenuItem({
  to,
  icon,
  title,
  badge = 0,
  id,
}: {
  to: string;
  icon: React.ReactNode;
  title: React.ReactNode;
  badge?: number;
  id?: string;
}) {
  const isExternalLink = to.startsWith("https");
  const linkClassName = clsx(
    "nav-link tw-group tw-relative tw-pl-[24px]",
    "focus-visible:tw-ring-2 focus-visible:tw-ring-blue-600",
  );

  const children = (
    <>
      {icon}
      <span className="tw-ml-1">{title}</span>

      {badge > 0 ? (
        <Badge srOnlyText="" className="tw-absolute tw-right-0 tw-top-0.5">
          {badge}
        </Badge>
      ) : null}
    </>
  );

  return (
    <li className="nav-item start" id={id}>
      {isExternalLink ? (
        <a href={to} rel="noopener noreferrer" target="_blank" className={linkClassName}>
          {children}
        </a>
      ) : (
        <NavLink to={to} className={linkClassName}>
          {children}
        </NavLink>
      )}
    </li>
  );
}
