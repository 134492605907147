import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router-dom";

import { getToken } from "../../actions/get_token";
import { deployedUSA } from "../../common/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import { Layout } from "../ui/layout";
import PlanCancel from "./3d_plan_correction_page";
import PlanCancelSmile from "./smile_correction_page";

export default function PlanCancelBody(props: RouteComponentProps<{ patient_id: string }>) {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    _paq.push(["HeatmapSessionRecording::enable"]);

    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }

    dispatch(getToken());
  }, [user, dispatch]);

  return (
    <Layout>
      <div className="row">
        <div className="col-md-12">
          {deployedUSA() ? <PlanCancel {...props} /> : <PlanCancelSmile {...props} />}
        </div>
      </div>
    </Layout>
  );
}
