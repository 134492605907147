import moment from 'moment';

import {
  API_CLINICS_LIST,
  API_GET_CLINIC,
  API_POST_CLINIC,
  API_UNLINK_CLINIC
} from '../config';
import type { TClinic, TUserActionNotification } from '../reducers/dashboard';
import type { AppDispatch } from '../store';
import { addUserActionNotification } from './user_notification';

export function setAddress(clinic: Partial<TClinic>) {
  if (clinic && !Array.isArray(clinic)) {
    const setAddressIfExists = (address: string | undefined) => address !== null ? `${address} ` : '';
    if (clinic && !Array.isArray(clinic)) {
      return `${setAddressIfExists(clinic.address_line_1)}${setAddressIfExists(clinic.address_line_2)}${setAddressIfExists(clinic.address_line_3)}`;
    }
  }
  return '';
}

function prepareSchedule(schedule) {
  const schedule_json = {};
  Object.keys(schedule).forEach((weekday) => {
    if (schedule[weekday].checked === true) {
      schedule_json[weekday] = {
        from: moment(schedule[weekday].from).format("HH:mm"),
        to: moment(schedule[weekday].to).format("HH:mm")
      }
    }
  });
  return schedule_json;
}

export function getClinic(clinic_id: TClinic["clinic_id"]) {
  return (dispatch: AppDispatch) => {
    fetch(API_GET_CLINIC(clinic_id), {credentials: 'include'})
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to fetch clinic');
      }
      return response;
    })
    .then((response) => response.json())
    .then(json => dispatch({ type: 'GET_CLINIC_SUCCESS', json }))
    .catch(() => {})
  }
}

export function getClinics() {
  return (dispatch: AppDispatch) => {
      dispatch({type: 'LOAD_SPINNER'})
      fetch(API_CLINICS_LIST, {credentials: 'include'})
      .then((response) => {
          if (!response.ok) {
              throw new Error('Error occurred trying to fetch clinics');
          }
          return response;
      })
      .then((response) => response.json())
      .then((json) => dispatch({type: 'GET_CLINICS_SUCCESS', json}))
      .catch(() => {})
      .finally(()=> dispatch({type: 'CLEAR_SPINNER'}))
  }
}

export function addClinic(formData, schedule) {

  const NOTIFICATION_MSG_OK = {
    message: "notify.add_clinic.ok",
    level: 'success',
    position: 'tl',
    autoDismiss: '4',
  };

  const NOTIFICATION_MSG_ERROR = {
    message: "notify.add_clinic.error",
    level: 'error',
    position: 'tl',
    autoDismiss: '4',
  }

  return (dispatch) => {
    fetch(API_POST_CLINIC, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        title: formData.title,
        phone: formData.phone,
        email: formData.email,
        contact: formData.contact,
        zip_code: formData.zip_code,
        schedule_json: prepareSchedule(schedule),
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        address_line_3: formData.address_line_3,
        logistic_phone: formData.logistic_phone,
        logistic_contact: formData.logistic_contact,
        city_id: formData.city_id ? formData.city_id.value : null
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to add clinic');
      }
      return response;
    })
    .then((response) => response.json())
    .then(json => {
      dispatch({type: 'POST_CLINIC_SUCCESS', json});
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
    }).catch(() => {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR))
    })
  }
}

export function unlinkClinic(clinic_id: TClinic["clinic_id"]) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.unlink_clinic.ok",
    level: 'success',
    position: 'tl',
    autoDismiss: 4,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
      message: "notify.unlink_clinic.error",
      level: 'error',
      position: 'tl',
      autoDismiss: 4,
    };

  return (dispatch: AppDispatch) => {
    fetch(API_UNLINK_CLINIC(clinic_id), {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error occurred trying to detach clinic');
        }
        return;
      })
      .then(() => {
        dispatch({ type: 'UNLINK_CLINICS_SUCCESS', clinic_id });
        dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      })
      .catch(() => {
        dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      })
  }
}
