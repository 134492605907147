import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { getClinics } from "../../../actions/clinics";
import { addInstructions } from "../../../actions/patient_new_instructions";
import remoteLog from "../../../common/logging";
import type { TClinic } from "../../../reducers/dashboard";
import type { RootState } from "../../../store";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    clinics: state.clinics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClinics: () => dispatch(getClinics()),
    addInstruction: (data) => dispatch(addInstructions(data)),
  };
};

type PatientNewDoctorInfoProps = PropsFromRedux & { intl: IntlShape };

class PatientNewDoctorInfo extends Component<PatientNewDoctorInfoProps> {
  constructor(props: PatientNewDoctorInfoProps) {
    super(props);
    this.addComments = this.addComments.bind(this);
    this.selectClinic = this.selectClinic.bind(this);
    this.selected = -1;
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_new_doctor_info");
  }

  componentDidMount() {
    if (this.props.clinics.length === 1) {
      this.selectClinic(this.props.clinics[0].clinic_id, 0);
    }
    this.props.getClinics();
  }

  UNSAFE_componentWillReceiveProps(newProps: PatientNewDoctorInfoProps) {
    if (newProps.user.account_id) {
      this.props.addInstruction({ doctor_id: newProps.user.account_id });
    }
  }

  componentDidUpdate(prevProps: PatientNewDoctorInfoProps) {
    if (
      this.props.clinics &&
      Array.isArray(this.props.clinics) &&
      this.props.clinics.length == 1 &&
      prevProps.clinics &&
      Array.isArray(prevProps.clinics) &&
      prevProps.clinics.length == 0 &&
      this.props.clinics !== prevProps.clinics
    ) {
      this.selectClinic(this.props.clinics[0].clinic_id, 0);
    }
  }

  addComments(data) {
    this.props.addInstruction({ logistics_comment: data });
  }

  selectClinic(data: TClinic["clinic_id"], i: number) {
    this.selected = i;
    this.props.addInstruction({ clinic_id: parseInt(data) });
    if (this.props.clinics.length > 1) {
      this.props.setDirty("clinic_id", data);
    }
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <h3 className="block" style={{ fontWeight: "900", marginTop: "0" }}>
          <FormattedMessage id="BLOCKHEAD_DOCTOR" />
        </h3>
        <div className="form-group" id="clinic-section">
          <label className="control-label" id="validation-clinic_id" style={{ fontWeight: "900" }}>
            <FormattedMessage id="HEADER_CLINIC" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <div className="radio-list" id="clinic-name-value">
            {this.props.clinics.map((c, i) => {
              return (
                <label key={c.clinic_id}>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        checked={i === this.selected}
                        name="clinic"
                        key={c.clinic_id}
                        value={c.clinic_id}
                        onChange={(e) => this.selectClinic(e.target.value, i)}
                      />
                    </span>
                  </div>{" "}
                  {c.title}
                </label>
              );
            })}
          </div>
          <div id="form_clinic_error"> </div>
        </div>
        {/*<div className="form-group" id="delivery-commentary-section">
          <label className="control-label" id="delivery-commentary-label"><FormattedMessage id="CLINIC_SHIP_COMMENTS" /></label>
          <textarea className="form-control" id="delivery-commentary-value" rows={5} name="logistics_comment" placeholder={this.props.intl.formatMessage({id: "CLINIC_SHIP_COMMENTS"})} defaultValue={""} onChange={(e) => this.addComments(e.target.value)} />
          </div>*/}
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNewDoctorInfo));
