import React, {Component} from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getPatientId } from '../../../../actions/get_patient_id';
import { getAuthorizeNetFormToken } from '../../../../actions/invoice';
import { PaymentMethod } from '../../../../common/constants';
import remoteLog from '../../../../common/logging';
import { FormatNumber } from "../../../common/FormatNumber";
import Loader from '../../../common/loadingInProgress';


const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    invoice: state.invoice,
    services: state.services
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    getAuthorizeNetFormToken: (patient_id,amount,payment_account) => dispatch(getAuthorizeNetFormToken(patient_id,amount,payment_account)),
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
    eraseStateProp: (props) => dispatch(eraseStateProp(props))
  }
}


class PaymentInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_id: null,
      amount: null,
      canSubmit: false,
      showLoader: false,
      paid: false,
      payment_account:false
    }
    this.requestToPay = this.requestToPay.bind(this);
    this.paymentOptions = this.paymentOptions.bind(this);
    this.acceptHostedAction = this.acceptHostedAction.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, 'payment_invoice');
  }

  setAmount(amount) {
    this.setState({ amount });
    this.setState({ canSubmit: true });
  }

  UNSAFE_componentWillMount() {
    let patient_id = this.props.match.params.patient_id;
    this.props.getPatient(patient_id);
    this.setState({ patient_id: patient_id });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.patient && nextProps.patient.course) {
      const { payments } = nextProps.patient.course;
      const paid_remain = payments['next'];
      const extra = payments.extra;
      const paid = nextProps.patient.course.correction.slice(-1)[0].price_value_paid;
      if (paid == 0 && (!extra || (extra && Array.isArray(extra) && extra.length == 0))) {
        const services = this.props.services;
        let extra_payments = null;
        if (services && Array.isArray(services) && services.length > 0) {
          extra_payments = services.filter(service => service.paid == false && service.tag == 'CBCT_ANALYSIS') || null;
          if (extra_payments) {
            extra_payments = extra_payments.pop();
          }
        }
        this.setState({ amount: { course: true, extra: extra_payments, price: paid_remain }, canSubmit: false, paid: false });
      } else if (extra && Array.isArray(extra) && extra.length > 0) {
        this.setState({ amount: null , canSubmit: false, paid: false });
      } else if (paid == paid_remain && (!extra || (extra && Array.isArray(extra) && extra.length == 0))) {
        this.setState({ paid: true });
      }
    }
    if (nextProps.invoice.invoice && nextProps.invoice.invoice.token && nextProps.invoice.invoice.payment_endpoint) {
      this.acceptHostedAction(nextProps.invoice.invoice.payment_endpoint, nextProps.invoice.invoice.token);
    }
  }

  acceptHostedAction(path, token) {
    const form = document.createElement('form');
    form.method = "post";
    form.action = path;

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = "token";
    hiddenField.value = token;
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  paymentOptions(course) {
    let payment_method = course.payment_method;
    if (payment_method == PaymentMethod.CARD) {
      const { remain } = course.payments;
      const services = this.props.services;
      let extra_payments = null;
      if (services && Array.isArray(services) && services.length > 0) {
        extra_payments = services.filter(service => service.paid == false && service.tag == 'CBCT_ANALYSIS') || null;
        if (extra_payments) {
          extra_payments = extra_payments.pop();
        }
      }

      {/*<div className="form-group">
        <div>
          <FormattedMessage id="pat.payments.amount.to.pay" />: <FormattedMessage id="pat.block.payment.currency.usa" /> <FormatNumber value={remain}/>
          <br/>
        </div>
      </div>*/}
      if (remain && remain > 0) {
        return (
            <div className="form-group">
              <div className="radio-list">
                <label className="control-label">Payments</label>
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        name="payment_option"
                        value={1}
                        onChange={() => this.setAmount({ price: remain, course: true, extra: extra_payments})}
                      />
                    </span>
                  </div>
                  <FormattedMessage id="pat.block.payment.currency.usa" /> <FormatNumber value={remain}/>
                </label>
              </div>
            </div>
          )
        }
      }
  }

  requestToPay() {
    if (this.state.amount) {
      this.setState({ showLoader: true, canSubmit: false });
      this.props.getAuthorizeNetFormToken(this.state.patient_id, this.state.amount,this.state.payment_account);
    }
  }

  renderExtraPayments(course) {
    if (course.payments && course.payments.extra && Array.isArray(course.payments.extra) && course.payments.extra.length > 0) {
      const { extra } = course.payments;
      const extra_payments = [];
      extra.forEach((e, index) => {
        extra_payments.push(
          <label key={e.id}>
            <div className="radio">
              <span>
                <input
                  type="radio"
                  name="payment_option"
                  value={index}
                  onChange={() => this.setAmount({ price: e.price, course: false, extra: { ...e }})}
                />
              </span>
            </div>
            <FormattedMessage id={e.tag} /> <FormattedMessage id="pat.block.payment.currency.usa" /> {e.price}
          </label>
        )
      })
      return (
        <div className="form-group">
          <div className="radio-list">
            <label className="control-label">Extra service payments:</label>
            {extra_payments}
          </div>
        </div>
      )
    }
  }

  onPaymentAccountChecked(){
    if (!this.state.payment_account){
      this.setState({payment_account:true})
    }else {
      this.setState({payment_account:false})
    }
  }

  render() {
    if(location.protocol === 'https:' && this.props.patient && this.props.patient.patient_id) {
      const { patient: { course } } = this.props;
      return (
                <div className="portlet light bordered">
                  <div className="portlet-title">
                    <div className="caption">
                      <i className="icon-book-open font-green" />
                      <span className="caption-subject font-green bold uppercase">
                        <FormattedMessage id="pat.payments.online.payment" />
                      </span>
                    </div>
                  </div>
                    <div className="portlet-body">
                      { this.paymentOptions(course) }
                      { this.renderExtraPayments(course) }
                      {!this.props.user.payment_account ?
                        <div className="checkbox" style={{padding: "5px 0 10px 25px"}}>
                          <input type="checkbox" id="payment_account" name="payment_account"
                                 value="payment_account"
                                 checked={this.state.payment_account}
                          onClick={()=>this.onPaymentAccountChecked()}/>
                          <label htmlFor="payment_account">Save my payment information</label>
                        </div>
                          : null
                      }
                      <div>
                        <button onClick={this.requestToPay} className="btn green" id="test-btn" ref="toggle-button" disabled={!this.state.canSubmit}>
                          <FormattedMessage id="pat.payments.buttons.pay" />
                        </button>
                      </div>
                      { this.state.showLoader && <Loader msg={this.props.intl.formatMessage({ id: "pat.payments.you.will.be.redirected" })}/> }
                    </div>
                </div>
      )
    }
    return null;
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(injectIntl(PaymentInvoice));
