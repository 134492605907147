import React from "react";
import { ErrorBoundary, type ErrorBoundaryProps, type FallbackProps } from "react-error-boundary";
import { FormattedMessage } from "react-intl";

import remoteLog from "../../common/logging";
import { Portlet, PortletTitle } from "../ui/portlet";

export function RemoteLogErrorBoundary({
  component,
  children,
  fallbackRender = portletFallbackRender,
}: {
  component: string;
  children: React.ReactNode;
  fallbackRender?: ErrorBoundaryProps["fallbackRender"] | null;
}) {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender ?? nullRender}
      onError={(err) => remoteLog(err, component)}
    >
      {children}
    </ErrorBoundary>
  );
}

function portletFallbackRender(_: FallbackProps) {
  return (
    <Portlet>
      <PortletTitle iconClassName="icon-info">
        <FormattedMessage id="error_boundary.title" />
      </PortletTitle>

      <p role="alert" className="tw-space-y-4">
        <span className="tw-block">
          <FormattedMessage id="error_boundary.message" />.
        </span>

        <span className="tw-block">
          <FormattedMessage id="error_boundary.message_2" />.
        </span>
      </p>
    </Portlet>
  );
}

function nullRender(_: FallbackProps) {
  return null;
}
