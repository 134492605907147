import type { Moment } from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";

import { getAllShipments, trackingLink, UPS_TRACK_LOCALE_MAP } from "../../actions/deliveries";
import { DATE_FORMAT, formatDate } from "../../common/datetime";
import remoteLog from "../../common/logging";
import { deployedUSA } from "../../common/utils";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import { FormatDate } from "../common/FormatDate";
import Loader from "../common/loadingInProgress";
import { PersonName } from "../common/PersonName";
import { Layout } from "../ui/layout";
import { Portlet, PortletTitle } from "../ui/portlet";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    lang: state.intl,
    shipments: state.shipments,
  };
};

const mapDispatchToProps = {
  getShipments: getAllShipments,
};

type ShipmentsListProps = PropsFromRedux & { intl: IntlShape };

type ShipmentsListState = {
  startDate: Moment | null;
  isLoading: boolean;
  without_tracking_number: boolean;
};

class ShipmentsPage extends Component<ShipmentsListProps, ShipmentsListState> {
  constructor(props: ShipmentsListProps) {
    super(props);
    this.state = {
      startDate: null,
      isLoading: false,
      without_tracking_number: deployedUSA(),
    };
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "shipments_page");
  }

  componentDidMount() {
    const { user } = this.props;
    if (deployedUSA()) {
      const without_tracking_number = this.state.without_tracking_number;
      this.props.getShipments(null, without_tracking_number);
    } else {
      this.props.getShipments();
    }

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }
    setDocumentTitle(this.props.intl.formatMessage({ id: "pat.shipments.header" }));
  }

  componentDidUpdate(prevProps: ShipmentsListProps) {
    if (this.props.shipments !== prevProps.shipments) {
      this.setState({ isLoading: false });
    }
  }

  onChangeDate(e: Moment) {
    const without_tracking_number = this.state.without_tracking_number;
    this.setState({ isLoading: true });
    const from = e && e._d ? formatDate(e._d)._i : null;
    this.setState({ startDate: e && e._d ? formatDate(e._d) : null });
    this.props.getShipments(from, without_tracking_number);
  }

  render() {
    const { isLoading } = this.state;
    const { shipments, lang } = this.props;
    const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale];

    return (
      <Layout>
        <Portlet as="main">
          <PortletTitle iconClassName="icon-users" id="patients-table-block-title">
            <FormattedMessage id="pat.shipments.header" />
          </PortletTitle>

          <p>
            <FormattedMessage id="pat.shipments.description" />
          </p>

          <label htmlFor="start_date">
            <FormattedMessage id="pat.shipments.selected.date" />
          </label>
          <DatePicker
            id="start_date"
            className="form-control"
            dateFormat={DATE_FORMAT}
            selected={this.state.startDate}
            onSelect={(e) => this.onChangeDate(e)}
            placeholderText={this.props.intl.formatMessage({ id: "date" })}
          />

          <div className="portlet-body">
            <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
              <div className="table-scrollable">
                <table
                  className="table table-bordered table-hover dataTable no-footer"
                  id="doctor_pacients_table"
                  role="grid"
                >
                  <thead id="patients-table-head">
                    <tr role="row">
                      <th style={{ width: 14 }}>#</th>
                      <th style={{ width: 111 }}>
                        <FormattedMessage id="pat_table.name" />
                      </th>
                      {deployedUSA() ? (
                        <th style={{ width: 150 }}>
                          <FormattedMessage id="pat.shipments.eta" />
                        </th>
                      ) : (
                        <th style={{ width: 150 }}>
                          <FormattedMessage id="pat.shipments.tracking_number" />
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody id="patients-table-body">
                    {!shipments.hasBeenLoaded || isLoading ? (
                      <tr className="clickable-row even" role="row">
                        <td colSpan={100} style={{ padding: 0 }}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      shipments.data.map((shipment, i) => (
                        <tr
                          key={"p" + i}
                          className={"clickable-row " + (i % 2 ? "even" : "odd")}
                          role="row"
                        >
                          <td className="sorting_1">{shipment.patient.patient_id}</td>
                          <td>
                            <NavLink to={`/pages/patient/${shipment.patient.patient_id}`}>
                              <PersonName person={shipment.patient} />
                            </NavLink>
                          </td>
                          {deployedUSA() ? (
                            <td>
                              {shipment.tracking_number !== null ? (
                                <a
                                  href={trackingLink(
                                    shipment.tracking_number,
                                    shipment.type,
                                    upsLocale,
                                  )}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <FormatDate value={shipment.pickup_date} date />
                                </a>
                              ) : (
                                <FormatDate value={shipment.pickup_date} date />
                              )}
                            </td>
                          ) : (
                            <td>
                              <a
                                href={trackingLink(
                                  shipment.tracking_number,
                                  shipment.type,
                                  upsLocale,
                                )}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {shipment.tracking_number}
                              </a>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(ShipmentsPage));
