import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  DeliveryDestination,
  trackingLink,
  UPS_TRACK_LOCALE_MAP,
} from "../../../actions/deliveries";
import { Status } from "../../../common/courses";
import remoteLog from "../../../common/logging";
import type { TPatient } from "../../../reducers/dashboard";
import type { RootState } from "../../../store";
import { Portlet, PortletTerm, PortletTitle } from "../../ui/portlet";

const DELIVERY_ON_STATUSES: TPatient["status"][] = [
  Status.PRODUCTION_PRINTING,
  Status.PRODUCTION_MOLDING,
  Status.PRODUCTION_MARKING,
  Status.PRODUCTION_ALLOCATING,
  Status.PRODUCTION_FABRICATING,
  Status.PRODUCTION_QUALITY_CONTROL,
  Status.FINAL_STAGE_READY,
];

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.intl,
    patient: state.patient,
    services: state.services,
  };
};

type PatientStatusProps = PropsFromRedux & { intl: IntlShape };

class PatientStatus extends Component<PatientStatusProps> {
  componentDidCatch(e: Error) {
    remoteLog(e, "patient_status");
  }

  renderDelivery() {
    const { patient, lang, intl } = this.props;
    const upsLocale = UPS_TRACK_LOCALE_MAP[lang.locale as IntlShape["locale"]];

    if (
      patient &&
      DELIVERY_ON_STATUSES.includes(patient.status) &&
      Array.isArray(patient.deliveries) &&
      patient.deliveries.length > 0
    ) {
      const delivery = patient.deliveries.find(
        (delivery) =>
          delivery.destination == DeliveryDestination.CLINIC &&
          delivery.called_by_phone &&
          delivery.pickup_date,
      );
      if (delivery != null && delivery.track_number !== null) {
        const pickup_date = moment(delivery.pickup_date);
        if (moment().diff(pickup_date, "months", true) <= 1) {
          return (
            <div>
              <p>
                <label id="status-info-side-block-aligner-delivery-label">
                  <strong id="status-info-side-block-aligner-delivery-value">
                    <FormattedMessage id="pat.block.deliveries.aligner.delivery" />
                  </strong>
                </label>
              </p>
              <p>
                <label id="status-info-side-block-ups-site-track-label">
                  <strong>{delivery.track_number}</strong>
                </label>
                &nbsp;&nbsp;
                <a
                  id="status-info-side-block-ups-site-track-link"
                  href={trackingLink(delivery.track_number, delivery.type, upsLocale)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i
                    className="icon-info font-green"
                    id="status-info-side-block-ups-site-track-value"
                    title={intl.formatMessage({ id: "pat.block.deliveries.ups.site.track" })}
                  />
                </a>
              </p>
              <NavLink to={`/pages/patient/${patient.patient_id}/deliveries`}>
                <strong id="status-info-side-block-all-deliveries">
                  (<FormattedMessage id="pat.block.deliveries.aligner.all" />)
                </strong>
              </NavLink>
            </div>
          );
        }
      }
    }
    return null;
  }

  render() {
    return (
      <Portlet id="status-info-side-block">
        <PortletTitle as="h2" iconClassName="icon-clock" id="status-info-side-block-name">
          <FormattedMessage id="pat.block.status.header" />
        </PortletTitle>

        <div className="portlet-body" id="status-info-side-block-body">
          <PortletTerm id="status-info-side-block-label">
            <FormattedMessage id="pat.block.status.status" />
          </PortletTerm>
          <p id="status-info-side-block-value">
            <FormattedMessage id={this.props.patient.status} />
          </p>
          {this.renderDelivery()}
        </div>
      </Portlet>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientStatus));
