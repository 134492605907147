import React, { Component, createRef } from "react";
import { FormattedMessage } from "react-intl";
import Modal from "react-modal";
import { connect, type ConnectedProps } from "react-redux";
import invariant from "tiny-invariant";

import { sendComment } from "../../actions/comments";
import type { RootState } from "../../store";

const mapDispatchToProps = {
  sendComment
};

const mapStateToProps = (state: RootState) => ({
  patient: state.patient
});

type PatientSendCommentProps = PropsFromRedux;

type PatientSendCommentState = {
  comment: string;
  isModalOpened: boolean;
};

class PatientSendComment extends Component<PatientSendCommentProps, PatientSendCommentState> {
  commentInputRef = createRef<HTMLTextAreaElement>();
  openModalButtonRef = createRef<HTMLButtonElement>();

  constructor(props: PatientSendCommentProps) {
    super(props);
    this.state = {
      comment: "",
      isModalOpened: false,
    };
  }

  openModal() {
    this.setState({ isModalOpened: true });
  }

  closeModal() {
    this.setState({ isModalOpened: false, comment: "" });
  }

  afterOpenModal() {
    if (this.commentInputRef.current) {
      this.commentInputRef.current.focus();
    }
  }

  afterCloseModal() {
    if (this.openModalButtonRef.current) {
      this.openModalButtonRef.current.focus();
    }
  }

  sendComment() {
    const { comment } = this.state;
    const trimmedComment = comment.trim();
    if (trimmedComment.length > 0) {
      invariant(
        this.props.patient.patient_id != undefined,
        "expected patient_id, but got undefined"
      );
      this.props.sendComment(this.props.patient.patient_id, trimmedComment);
    }
    this.setState({ comment: "" });
    this.closeModal();
  }

  render() {
    const { comment, isModalOpened } = this.state;
    const isCommentSubmitDisabled = comment.trim() == "";

    return (
      <div>
        <button
          ref={this.openModalButtonRef}
          id="add-comment-btnnn comment-button-add-question"
          className="btn green"
          style={{ margin: 10, whiteSpace: "pre-line" }}
          type="button"
          onClick={() => this.openModal()}
        >
          <FormattedMessage id="pat.comment.button" />
        </button>

        <Modal
          className="question-modal"
          isOpen={isModalOpened}
          onRequestClose={() => this.closeModal()}
          onAfterOpen={() => this.afterOpenModal()}
          onAfterClose={() => this.afterCloseModal()}
          style={{ overlay: { backgroundColor: "#787d7d7d" } }}
        >
          <form
            id="comment-question-modal"
            className="portlet-body form"
            onSubmit={(event) => {
              event.preventDefault();
              this.sendComment();
            }}
          >
            <div className="form-body">
              <div className="form-group">
                <label
                  id="comment-question-modal-label"
                  className="control-label"
                  htmlFor="comment-question-modal-input"
                  style={{ paddingBottom: 15 }}
                >
                  <FormattedMessage id="pat.comment.button" />
                </label>
                <textarea
                  ref={this.commentInputRef}
                  id="comment-question-modal-input"
                  className="form-control"
                  name="comment"
                  rows={5}
                  value={comment}
                  onChange={(event) => this.setState({ comment: event.currentTarget.value })}
                />
              </div>
            </div>

            <div
              id="comment-question-modal-buttons"
              className="form-actions"
              style={{ backgroundColor: "white" }}
            >
              <button
                id="add-comment-btnnn comment-question-modal-submit"
                className="btn green"
                style={{ margin: 10 }}
                type="submit"
                disabled={isCommentSubmitDisabled}
              >
                <FormattedMessage id="BTN_SUBMIT" />
              </button>
              <button
                id="add-comment-btnnn comment-question-modal-cancel"
                className="btn grey"
                style={{ margin: 10 }}
                type="button"
                onClick={() => this.closeModal()}
              >
                <FormattedMessage id="pat.deliveries.button.cancel" />
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientSendComment);
